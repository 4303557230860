import { IOrderStatusProps } from "pages/orders/DetailsOrder/SelectedStatus";

export const orderStatusMessages: Array<IOrderStatusProps> = [
  {
    title: "Pendente",
    detail: "Por algum motivo seu pedido está pendente",
  },
  {
    title: "Em Preparo",
    detail: "O pedido foi aceito e já está em preparação.",
  },
  {
    title: "Pronto",
    detail: "O pedido está pronto aguardando para ser entregue.",
  },
  {
    title: "A Caminho",
    detail: "O pedido está em rota de entrega e logo será entregue ao cliente.",
  },
  {
    title: "Entregue",
    detail: "O cliente já recebeu o pedido em seu endereço.",
  },
  {
    title: "Recusado",
    detail: "Por algum motivo esse pedido não pôde ser aceito.",
  },
];
