import { createContext, useEffect, useState } from "react";
import { isValidToken } from "shared/utils/token";

interface AuthContextData {
  signed: boolean;
  dispatch: (state: boolean) => void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [signed, setSigned] = useState(false);
  const [loading, setLoading] = useState(true);

  function dispatch(state: boolean) {
    setSigned(state);
    setLoading(false);
  }

  function displayMessage(evt: any) {
    setLoading(false);

    const isValidData = typeof evt.data === "string" && evt.data !== "";
    let json = isValidData ? JSON.parse(evt.data) : undefined;
    let validToken = false;

    if (json && !!json.token && json.token.access_token) {
      localStorage.setItem("token", json.token.access_token);
      localStorage.setItem("refresh_token", json.token.refresh_token);
      validToken = isValidToken(json.token.access_token);
    } else {
      json = {
        token: {
          access_token: localStorage.getItem("token"),
          refresh_token: localStorage.getItem("refresh_token"),
        },
      };

      validToken = !!localStorage.getItem("token") && isValidToken(localStorage.getItem("token"));
    }

    setLoading(false);
    setSigned(validToken);
  }

  useEffect(() => {
    window.addEventListener("message", displayMessage, false);

    return () => {
      window.removeEventListener("message", displayMessage, false);
    };
  });

  return (
    <AuthContext.Provider
      value={{
        signed: !!localStorage.getItem("token") ?? signed,
        dispatch,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
