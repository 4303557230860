import { IEmptyArea } from "../types";
import { Container, Content } from './styles';

export default function EmptyArea(props: IEmptyArea) {
  const { text } = props;
  return (
    <Container>
      <Content>
        <img src="/images/empty_area.svg" alt="empty_area" />
        <p>{text}</p>
      </Content>
    </Container>
  )
}