import { PhotoCamera } from "@material-ui/icons";
import {
  Container,
  ImagePreview,
  Input,
  InputLabel,
  ReplaceBtn,
} from "./styles";
import { IImageInput } from "./types";

export default function ImageInput(props: IImageInput) {
  const { image, setImage, setFile } = props;

  const handleOnChange = (e: any) => {
    const [file] = e.target.files;
    if (file) {
      setFile(file);
      setImage(URL.createObjectURL(file));
    }
  };

  return (
    <Container>
      <Input
        hidden
        type="file"
        id="submit-img"
        accept="image/*"
        onChange={handleOnChange}
      />
      <InputLabel htmlFor="submit-img">
        {!image ? (
          <>
            <PhotoCamera />
            <p>Adicionar foto</p>
          </>
        ) : (
          <>
            <ImagePreview src={image} />
            <ReplaceBtn startIcon={<PhotoCamera />} />
          </>
        )}
      </InputLabel>
    </Container>
  );
}
