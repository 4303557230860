import CurrencyInput from "react-currency-input-field";

export function InputCurrency({
  error,
  helperText,
  setValue,
  value,
}: {
  error?: boolean;
  helperText: string;
  setValue: (value: string) => void;
  value: string | number | undefined;
}) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#f3f3f3",
          position: "relative",
          borderRadius: "4px",
          display: "inline-flex",
          width: "100%",
          padding: "18.5px 14px",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "8px" }}>
          <p
            style={{
              fontSize: "1rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
              lineHeight: 1.5,
              letterSpacing: "0.00938em",
              color: "rgba(0, 0, 0, 0.54)",
            }}
          >
            R$
          </p>
        </div>
        <CurrencyInput
          decimalSeparator=","
          groupSeparator="."
          value={value}
          className="MuiInputBase-input MuiInputBase-inputAdornedStart MuiOutlinedInput-inputAdornedStart"
          onValueChange={(value) => {
            setValue(value ?? "");
          }}
        />
      </div>
      {error && (
        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
          {helperText}
        </p>
      )}
    </>
  );
}
