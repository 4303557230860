import React from "react";

export interface ISVGProps{
  size?: string;
  color?: string;
}

const WhatsAppIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return(
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
    >
      <g id="Grupo_128" data-name="Grupo 128" transform="translate(-572 -2733)">
        <path id="whatsapp" d="M17.254,2.906a9.913,9.913,0,0,0-15.6,11.958L.25,20,5.5,18.622a9.9,9.9,0,0,0,4.736,1.206h0a9.915,9.915,0,0,0,7.01-16.922ZM10.244,18.154h0a8.227,8.227,0,0,1-4.193-1.148l-.3-.179-3.118.818.832-3.04-.2-.312a8.237,8.237,0,1,1,6.978,3.86Zm4.518-6.169c-.248-.124-1.465-.723-1.692-.806s-.392-.124-.557.124-.64.806-.784.971-.289.186-.537.062A6.764,6.764,0,0,1,9.2,11.107,7.47,7.47,0,0,1,7.823,9.392c-.144-.248,0-.369.109-.505a7,7,0,0,0,.619-.847.455.455,0,0,0-.021-.434c-.062-.124-.557-1.343-.763-1.839s-.405-.417-.557-.425-.309-.009-.475-.009a.91.91,0,0,0-.66.31,2.777,2.777,0,0,0-.867,2.066,4.817,4.817,0,0,0,1.011,2.562,11.038,11.038,0,0,0,4.23,3.739,14.174,14.174,0,0,0,1.412.522,3.4,3.4,0,0,0,1.56.1,2.551,2.551,0,0,0,1.672-1.177,2.069,2.069,0,0,0,.144-1.178c-.062-.1-.227-.165-.474-.289Zm0,0" transform="translate(573.75 2735)"
          fill={color || "#707070"} fill-rule="evenodd"
        />
        <path id="Caminho_26" data-name="Caminho 26" d="M0,0H24V24H0Z" transform="translate(572 2733)" fill="none"/>
      </g>
    </svg>
  );
}

export default WhatsAppIcon;