import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { useFilterListOrders } from "modules/orders/hooks/filter-list-orders";
import { OrderRepository } from "modules/orders/repositories/OrderRepository";
import { ResumeOrder } from "modules/orders/types/DetailsOrderRemote";
import ListOrder from "./List";
import { ListFilterOrder } from "./ListFilter";
import { Container, GridHeader, Header, Title } from "./styles";

type Props = {
  repository: OrderRepository;
};

export default function OrderManagementPage({ repository }: Props) {
  const {
    filter,
    filterUpdate,
    filterReset,
    filterOrder,
  } = useFilterListOrders();

  const history = useHistory();

  function handleSelectedOrder(resumeOrder: ResumeOrder, orderStatus: number) {
    if (orderStatus === 0) {
      history.push(`/orders/${resumeOrder.identifier}/new`);
    } else {
      history.push(`/orders/${resumeOrder.identifier}/details`);
    }
  }

  return (
    <Container>
      <Header>
        <GridHeader>
          <Title>{process.env.REACT_APP_NAME}</Title>
        </GridHeader>
        <Grid>
          <ListFilterOrder
            filter={filter}
            filterUpdate={filterUpdate}
            filterReset={filterReset}
          />
        </Grid>
      </Header>
      <ListOrder
        handleSelectedOrder={handleSelectedOrder}
        filterOrder={filterOrder}
        repository={repository}
        selectedFilter={filter}
      />
    </Container>
  );
}
