import React, { useState } from "react";
import InputMask from "react-input-mask";

const PhoneInput = ({ className, ...props }: any) => {
  const [mask, setMask] = useState("(99) 99999-9999");

  return (
    <div
      style={{
        backgroundColor: "#f3f3f3",
        position: "relative",
        borderRadius: "4px",
        display: "inline-flex",
        width: "100%",
        padding: "18.5px 14px",
        alignItems: "center",
      }}
    >
      <InputMask
        {...props}
        className="MuiInputBase-input MuiInputBase-inputAdornedStart MuiOutlinedInput-inputAdornedStart"
        mask={mask}
        onBlur={(e) => {
          if (e.target.value.replace("_", "").length === 14) {
            setMask("(99) 9999-9999");
          }
        }}
        onFocus={(e) => {
          if (e.target.value.replace("_", "").length === 14) {
            setMask("(99) 99999-9999");
          }
        }}
      >
        {(inputProps: any) => <input {...inputProps} type="tel" />}
      </InputMask>
    </div>
  );
};

export default PhoneInput;
