import { EStatusOrder } from "modules/orders/types";
import { KeyValuePair } from "shared/models/KeyValuePair";

const pallete: Array<KeyValuePair<EStatusOrder, string>> = [
  {
    Key: "Pendente",
    Value: "#EB0006",
  },
  {
    Key: "Em Preparo",
    Value: "#FFB200",
  },
  {
    Key: "Pronto",
    Value: "#006fff",
  },
  {
    Key: "A Caminho",
    Value: "#3a2780",
  },
  {
    Key: "Entregue",
    Value: "#009400",
  },
  {
    Key: "Recusado",
    Value: "#808080",
  },
];

export default function BackgroundColorSelector(status: EStatusOrder) {
  let selectedColor = "";

  pallete.forEach((item) => {
    if (item.Key === status) selectedColor = item.Value;
  });

  return selectedColor;
}
