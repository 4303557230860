import MttGrid from "shared/components/MttGrid/MttGrid";
import styled from "styled-components";

export const Title = styled(MttGrid)`
  text-align: center;
  padding: 0 16px;
  margin-bottom: 16px;
  white-space: pre-wrap;
  font-size: ${window.innerWidth < 320 ? "17px" : "20px"};
  color: #000000;
  font-weight: bold;
`;

export const Subtitle = styled(MttGrid)`
  text-align: center;
  margin-bottom: 8px;
  padding: 0 16px;

  font-size: 14px;
  color: #000000;
  font-weight: bold;
`;

export const ContainerButtons = styled(MttGrid)`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridButtons = styled(MttGrid)<{ dialog?: boolean }>`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;

  padding: 0 16px;

  .refuseChoiceButton {
    width: 48%;
  }
  .aceptChoiceButton {
    width: 48%;
  }
`;

export const StyledButtonRefuse = styled(MttGrid).attrs({
  className: "refuseChoiceButton",
})``;

export const StyledButtonAccept = styled(MttGrid).attrs({
  className: "aceptChoiceButton",
})``;

export const StyledButtonDeny = styled(MttGrid).attrs({
  className: "denyChoiceButton",
})``;

export const OrderGrid = styled(MttGrid)`
  width: 100%;

  padding: 12px 16px;

  border-bottom: 1px solid #00000020;
`;

export const GridAddress = styled(MttGrid)`
  width: 100%;
  padding: 0 16px;
  border-bottom: 1px solid #00000020;
`;

export const GridItemsOrder = styled(MttGrid)`
  width: 100%;
  padding: 0 16px;
`;
