import { LeftInfos, OrderInfos, PriceGrid, RightInfos } from "./styles";

import { formatDecimalToCurrency } from "shared/utils/math";
import { EPaymentType } from "modules/orders/types";
import { getPaymentIcon } from "modules/orders/utils/IconSelector/paymentIconSelector";
import { dateUTCAmerica_SaoPaulo } from "shared/utils/date";

type Props = {
  valorTotal: number;
  onlyValue?: boolean;
  payment: EPaymentType;
  date: Date;
};

export default function OrderHour(props: Props) {
  const { valorTotal, onlyValue, payment, date } = props;
  const paymentIcon = getPaymentIcon(payment);

  return (
    <>
      {!onlyValue ? (
        <OrderInfos>
          <LeftInfos>Hora do Pedido</LeftInfos>
          <RightInfos>{dateUTCAmerica_SaoPaulo(date)}</RightInfos>
        </OrderInfos>
      ) : (
        <> </>
      )}
      <OrderInfos>
        <LeftInfos>Valor do pedido</LeftInfos>
        <PriceGrid>
          {formatDecimalToCurrency(valorTotal)}
          {paymentIcon}
        </PriceGrid>
      </OrderInfos>
    </>
  );
}
