import { formatDecimalToCurrency } from "shared/utils/math";
import BackgroundColorSelector from "modules/orders/utils/BackgroundColorSelector";
import {
  ContentStatus,
  GridBody,
  GridDescription,
  GridItem,
  GridStatus,
  TextDefault,
  TitleCostumer,
  SubTitle,
  GridValue,
  TextPrice,
  ContentStatusAndTotalValue,
} from "./styles";
import { statusTypeToStatusNumber } from "modules/orders/utils";
import IconSelector from "modules/orders/utils/IconSelector";
import { ResumeOrder } from "modules/orders/types/DetailsOrderRemote";
import { dateUTCAmerica_SaoPaulo } from "shared/utils/date";
import { createCodeFromOrderNumber } from "shared/utils/string";

type Props = {
  client: {
    item: ResumeOrder;
    index: number;
  };
  handleSelectedOrder(order: ResumeOrder, index: number): void;
  isMobile: boolean;
};

export function ListItemOrder({
  client,
  handleSelectedOrder,
  isMobile,
}: Props) {
  const { item } = client;

  return (
    <GridItem
      isMobile={isMobile}
      className="order-item"
      focus={BackgroundColorSelector(item.status)}
      onClick={() => {
        handleSelectedOrder(item, statusTypeToStatusNumber(item.status));
      }}
    >
      <GridStatus className="order-icon">
        <ContentStatus
          id="iconGrid"
          status={BackgroundColorSelector(item.status)}
        >
          {IconSelector(item.status)}
        </ContentStatus>
      </GridStatus>

      <GridBody>
        <TitleCostumer>{item.userName}</TitleCostumer>
        <ContentStatusAndTotalValue>
          <div>
            <GridDescription className="order-description">
              <TextDefault>
                <SubTitle>{item.status}</SubTitle> •{" "}
                <span>{createCodeFromOrderNumber(item.requestNumber)}</span>
              </TextDefault>
              <TextDefault>{dateUTCAmerica_SaoPaulo(item.created)}</TextDefault>
            </GridDescription>
          </div>
          <div>
            <GridValue>
              <TextDefault>
                {item.numberOfItems} {item.numberOfItems > 1 ? "itens" : "item"}
              </TextDefault>
              <TextPrice>{formatDecimalToCurrency(item.total)}</TextPrice>
            </GridValue>
          </div>
        </ContentStatusAndTotalValue>
      </GridBody>
    </GridItem>
  );
}
