import { useContext, useEffect, useState } from "react";
import { UpdateOrder } from "modules/services/OrderService";
import OrderItems from "modules/orders/components/OrderItens";
import { ClientOrder } from "modules/orders/types/ClientOrder";
import { createForecastFromSelectedTime } from "shared/utils/date";
import { KeyValuePair } from "shared/models/KeyValuePair";
import MttButton from "shared/components/MttButton/MttButton";
import MttInput from "shared/components/MttInput";
import MttSelect from "shared/components/MttSelect";
import OrderAddress from "modules/orders/components/OrderAddress";
import OrderHour from "modules/orders/components/OrderHour";

import {
  Container,
  FullPage,
  GridConfirmButton,
  GridTimeDelivery,
  SError,
  Title,
  TitleResponsive,
} from "./styles";
import {
  btnConfirmText,
  errorInvalidFormText,
  errorInvalidTimeText,
  errorNotDefinedText,
  formMessage,
  modalTitle,
} from "./strings";
import AuthContext from "shared/contexts/auth";
import { SimpleAlert } from "shared/components/SimpleAlert";
import { useAlert } from "shared/hooks/useAlert";
import { createCodeFromOrderNumber } from "shared/utils/string";

type IOrderSummaryProps = {
  order: ClientOrder;
  sizeTitle?: number;
  finish(): void;
};

export default function OrderSummary(props: IOrderSummaryProps) {
  const { order, sizeTitle, finish } = props;
  const [value, setValue] = useState<string>("");
  const { dispatch } = useContext(AuthContext);
  const alert = useAlert();

  const baseOptions: Array<KeyValuePair<number, string>> = [
    {
      Key: 0,
      Value: "Min",
    },
    {
      Key: 1,
      Value: "Hora(s)",
    },
    {
      Key: 2,
      Value: "Dia(s)",
    },
  ];
  const [timeSelected, setTimeSelected] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  function handleSelectTime(time: string) {
    setTimeSelected(time);
  }

  async function confirmOrder() {
    try {
      if (validateDeliveryTime()) {
        setErrorMessage("");

        const forecastFromSelectTime = createForecastFromSelectedTime(
          timeSelected,
          value
        );

        order.order.deliveryTime = {
          value: parseInt(value),
          timeType: forecastFromSelectTime.unit,
        };

        const result = await UpdateOrder(
          {
            order_id: order.order.uuid,
            status: 1,
            forecast: forecastFromSelectTime.forecastUTC0.toISOString(),
          },
          dispatch
        );

        if (result.StatusCode !== 200) throw result;

        alert.setAlertMessage("Aceito com sucesso", "success", true);

        setTimeout(() => {
          finish();
        }, 1000);
      }
    } catch (e: any) {
      if (e.message === "Invalid time value") {
        setErrorMessage(errorInvalidTimeText);
      } else {
        setErrorMessage(errorNotDefinedText + " " + e.message);
      }
    }
  }

  function validateDeliveryTime(): boolean {
    if (!value || !timeSelected) {
      setErrorMessage(errorInvalidFormText);
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (!!errorMessage) {
      alert.setAlertMessage(errorMessage, "error", true);
    } else {
      alert.clearAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <>
      <FullPage className="OrderSummary">
        <Container>
          <Title>{modalTitle}</Title>
          <TitleResponsive>{`${order.clientName} • ${createCodeFromOrderNumber(
            order?.order.requestNumber
          )}`}</TitleResponsive>
          <OrderHour
            valorTotal={order.order.totalValue}
            payment={order.order.paymentType}
            date={order.order.date}
          />

          <OrderAddress address={order.order.address} sizeTitle={sizeTitle} />
          <OrderItems order={order} sizeTitle={sizeTitle} />
          <TitleResponsive>{formMessage}</TitleResponsive>
          <GridTimeDelivery>
            <MttInput
              value={value}
              onChange={(e) => {
                setValue(e.currentTarget.value);
              }}
              type="number"
              variant="outlined"
            />
            <MttSelect
              variant="outlined"
              value={timeSelected}
              values={baseOptions}
              onChange={(e) => {
                handleSelectTime(String(e.target.value));
              }}
            />
          </GridTimeDelivery>
          {errorMessage !== "" && <SError>{errorMessage}</SError>}
          <GridConfirmButton>
            <MttButton
              variant="contained"
              color="inherit"
              onClick={() => confirmOrder()}
            >
              {btnConfirmText}
            </MttButton>
          </GridConfirmButton>
        </Container>
      </FullPage>
      <SimpleAlert
        message={alert.message}
        type={alert.type}
        show={alert.show}
        setShow={alert.setShow}
      />
    </>
  );
}
