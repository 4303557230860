import { GetCompanyById } from "../../services/CompanyService";
import { CompanyRemoteFactory } from "../factories/CompanyRemoteFactory";

export class CompanyRepository {
  constructor(
    private readonly remoteFactory: CompanyRemoteFactory,
    private readonly dispatch: (state: boolean) => void
  ) {}

  async findCompany(uuid: string) {
    const resp: any = await GetCompanyById(uuid, this.dispatch);
    return this.remoteFactory.factoryMethod(resp.Data.data);
  }
}
