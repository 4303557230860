import ExpandLessIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import { useEffect, useState } from "react";
import {
  DeliveryInfos,
  DescriptionDelivery,
  GridTitle,
  TitleLeft,
} from "./styles";

type Props = {
  address: string;
  sizeTitle?: number;
};

export default function OrderAddress(props: Props) {
  const { address, sizeTitle } = props;
  const [expand, setExpand] = useState<boolean>(false);
  const [expandIcon, setExpandIcon] = useState<JSX.Element>();

  useEffect(() => {
    expand
      ? setExpandIcon(<ExpandLessIcon />)
      : setExpandIcon(<ExpandMoreIcon />);
  }, [expand]);

  return (
    <>
      <DeliveryInfos onClick={() => setExpand(!expand)}>
        <GridTitle>
          <TitleLeft sizeTitle={sizeTitle}>Entrega</TitleLeft>
          {expandIcon}
        </GridTitle>
        {expand ? <DescriptionDelivery>{address}</DescriptionDelivery> : <></>}
      </DeliveryInfos>
    </>
  );
}
