import { useCallback, useState } from "react";
import { ClientOrder } from "../types/ClientOrder";
import { OrderRepository } from "../repositories/OrderRepository";
import { paymentTypeTexts, statusTypeTexts } from "../types/typesTexts";

export function useFetchFindOrder(repository: OrderRepository) {
  const [order, setOrder] = useState<ClientOrder>({
    clientName: "",
    phoneNumber: "",
    order: {
      uuid: "",
      date: new Date(),
      address: "",
      forecast: new Date(),
      items: [],
      requestNumber: "",
      paymentType: paymentTypeTexts.MONEY,
      status: statusTypeTexts.PENDING,
      deliveryTime: {
        value: 0,
        timeType: "Min",
      },
      totalValue: 0,
    },
  });
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<string>();

  const findOrder = useCallback(
    async (orderId: string) => {
      try {
        setFetching(true);

        const resp = await repository.findOrder(orderId);

        setOrder(resp);
        setFetching(false);
      } catch (err: any) {
        setError(err.message);
        setFetching(false);
      }
    },
    [repository]
  );

  return {
    order,
    fetching,
    findOrder,
    errorMessage: error,
  };
}
