import { useEffect, useState } from "react";

export const messageRequiredField = "Campo Obrigatório.";
export const messageInvalidValue = "Valor inválido.";

export function useValidFormProduct({
  name,
  price,
}: {
  name: string;
  price: string;
}) {
  const [isValidForm, setIsValidForm] = useState(false);
  const [errors, setErrors] = useState<{ name: string; price: string }>({
    name: "",
    price: "",
  });

  useEffect(() => {
    let isValidForm = true;
    if (errors.name !== "") isValidForm = false;
    if (errors.price !== "") isValidForm = false;

    setIsValidForm(isValidForm);
  }, [errors]);

  useEffect(() => {
    setErrors({
      ...errors,
      name: !name ? messageRequiredField : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    const replacedPrice = price.replace(/\./gi, "").replace(",", ".");
    const value = Number(replacedPrice);

    setErrors({
      ...errors,
      price: isNaN(value) || value === 0 ? messageInvalidValue : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  return {
    errors,
    isValidForm,
    setErrors,
  };
}
