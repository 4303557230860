export const modalTitle = "Resumo do pedido:";

export const formMessage = `Qual o prazo para entrega?`;

export const errorInvalidTimeText = "Tempo Inválido.";

export const errorNotDefinedText = `Algo deu errado: `;

export const errorInvalidFormText = `Dados obrigatórios`;

export const btnConfirmText = `confirmar`;
