import MttButton from "shared/components/MttButton/MttButton";
import MttGrid from "shared/components/MttGrid/MttGrid";
import styled from "styled-components";

export const Container = styled(MttGrid)`
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(MttGrid)`
  text-align: center;
  margin-bottom: 16px;

  font-size: 20px;
  color: #000000;
  font-weight: bold;
`;

export const Warning = styled(MttGrid)`
  color: #ff0000;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
`;

export const ContactButtons = styled(MttGrid)`
  width: 100%;
  display: flex;

  justify-content: space-between;

  padding: 8px 0;

  .whatsAppButton {
    width: 48%;
  }

  .phoneButton {
    width: 48%;
  }
`;

export const WhatsButtonGrid = styled(MttGrid).attrs({
  className: "whatsAppButton",
})`
  .MuiButton-root {
    text-transform: capitalize;
    font-size: 12px;
  }
`;

export const PhoneButtonGrid = styled(MttGrid).attrs({
  className: "phoneButton",
})`
  .MuiButton-root {
    text-transform: capitalize;
    font-size: 12px;
  }
`;

export const StyledMttButton = styled(MttButton).attrs({
  variant: "contained",
  color: "inherit",
})``;

export const ReturnButtonGrid = styled(MttButton).attrs({
  className: "grayButton",
})`
  width: 100%;

  .MuiButton-colorInherit {
    width: 100%;
  }
`;
