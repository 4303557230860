import PhoneIcon from "@material-ui/icons/Phone";
import {
  ContactButtons,
  Container,
  PhoneButtonGrid,
  ReturnButtonGrid,
  StyledMttButton,
  Title,
  Warning,
  WhatsButtonGrid,
} from "./styles";
import MttDialog from "shared/components/MttDialog";
import WhatsAppIcon from "assets/icons/WhatsApp";
import { ClientOrder } from "modules/orders/types/ClientOrder";
import {
  btnBackToOrdersText,
  btnPhoneText,
  btnWhatsAppText,
  warningText,
} from "./strings";
import MttButton from "shared/components/MttButton/MttButton";

type Props = {
  open: boolean;
  order: ClientOrder;
  finish: () => void;
};

export default function OrderRefusedDialog(props: Props) {
  const { open, finish, order } = props;

  return (
    <MttDialog open={open} style={{ margin: "-12px" }}>
      <Container>
        <Title>{`${order.clientName} • ${order.order.requestNumber}`}</Title>
        <Warning>{warningText}</Warning>
        <ContactButtons>
          <WhatsButtonGrid>
            <MttButton
              variant="contained"
              color="inherit"
              startIcon={<WhatsAppIcon color="#FFF" />}
              onClick={() => {
                window.open(
                  `https://api.whatsapp.com/send?phone=${order.phoneNumber}`
                );
              }}
            >
              {btnWhatsAppText}
            </MttButton>
          </WhatsButtonGrid>
          <PhoneButtonGrid>
            <MttButton
              variant="contained"
              color="inherit"
              startIcon={<PhoneIcon />}
              onClick={() => {
                window.open(`tel:${order.phoneNumber}`);
              }}
            >
              {btnPhoneText}
            </MttButton>
          </PhoneButtonGrid>
        </ContactButtons>
        <ReturnButtonGrid>
          <StyledMttButton onClick={finish}>
            {btnBackToOrdersText}
          </StyledMttButton>
        </ReturnButtonGrid>
      </Container>
    </MttDialog>
  );
}
