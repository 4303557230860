import { ProductRepository } from "modules/products/repositories/ProductRepository";
import NewProduct from "pages/products/NewProduct";
import ProductManagement from "pages/products/ProductManagement";
import { ProductRemoteFactory } from "./ProductRemoteFactory";

export function PagesFactory(dispatch: (state: boolean) => void) {
  const remoteFactory = new ProductRemoteFactory();
  const repository = new ProductRepository(remoteFactory, dispatch);

  const makeManagementPage = () => {
    return <ProductManagement repository={repository} />;
  };

  const makeNewProductPage = () => {
    console.log("makeNewProductPage");
    return <NewProduct repository={repository} />;
  };

  return {
    makeManagementPage,
    makeNewProductPage,
  };
}
