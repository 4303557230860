import { Card } from "@material-ui/core";
import styled from "styled-components";
import palette from "shared/theme/palette";
import MttGrid from "shared/components/MttGrid/MttGrid";

export const Container = styled(Card)`
  align-items: center;
  display: flex;
  border-bottom: 2px solid ${palette.lightGray};
  width: 100vw;
  justify-content: space-between;
  svg {
    color: ${palette.gray};
  }
`;
export const ImageContainer = styled(MttGrid)`
  height: 98px;
  max-width: 108px;
  width: 30%;
`;

export const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const ItemContent = styled(MttGrid)`
  max-width: 203px;
  padding: 0 8px;
  width: 80%;
`;

export const Title = styled(MttGrid)`
  font-weight: 900;
`;

export const Description = styled(MttGrid)`
  color: ${palette.gray};
  font-size: 0.9em;
`;
