import { useEffect, useState } from "react";
import { Grid, IconButton, Paper } from "@material-ui/core";
import { useFetchFindOrdersByStatus } from "modules/orders/hooks/use-fetch-find-orders-by-status";
import { EStatusOrder } from "modules/orders/types";
import { OrderRepository } from "modules/orders/repositories/OrderRepository";
import { ResumeOrder } from "modules/orders/types/DetailsOrderRemote";
import { SimpleAlert } from "shared/components/SimpleAlert";
import { Loading } from "shared/components/Loading";
import { KeyValuePair } from "shared/models/KeyValuePair";
import useDeviceDetect from "shared/hooks/useDeviceDetect";
import { ListItemOrder } from "./ListItem";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ContentButtonRefresh } from "./styles";

export type ListOrderProps = {
  handleSelectedOrder(order: ResumeOrder, index: number): void;
  filterOrder(orders: ResumeOrder[]): KeyValuePair<number, ResumeOrder>[][];
  repository: OrderRepository;
  selectedFilter: Array<KeyValuePair<boolean, EStatusOrder>>;
};

export default function ListOrder({
  handleSelectedOrder,
  filterOrder,
  repository,
  selectedFilter,
}: ListOrderProps) {
  const {
    orders,
    fetching,
    errorMessage,
    fetchFindOrders,
  } = useFetchFindOrdersByStatus(repository);

  const [hasError, setHasError] = useState(false);

  const [listOrder, setListOrder] = useState<
    Array<KeyValuePair<number, ResumeOrder>[]>
  >();

  async function requestItems() {
    await fetchFindOrders();
  }

  function requestItemsWithTimer() {
    setTimeout(function () {
      requestItems().finally(() => {
        requestItemsWithTimer();
      });
    }, 60000);
  }

  useEffect(() => {
    requestItemsWithTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setListOrder(filterOrder(orders));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setListOrder, filterOrder, orders]);

  const { isMobile } = useDeviceDetect();

  function renderItems(list: KeyValuePair<number, ResumeOrder>[][]) {
    const renderOrders: JSX.Element[] = [];

    let none: boolean = true;
    selectedFilter.forEach((filtro, index) => {
      if (filtro.Key === true) {
        none = false;
        list[index].forEach((item) => {
          renderOrders.push(
            <ListItemOrder
              key={`${index}-${item.Key}`}
              client={{ item: item.Value, index: item.Key }}
              handleSelectedOrder={handleSelectedOrder}
              isMobile={isMobile}
            />
          );
        });
      }
    });

    if (none === true) {
      list.forEach((order) =>
        order.forEach((item, index) => {
          renderOrders.push(
            <ListItemOrder
              key={`${index}-${item.Key}`}
              client={{ item: item.Value, index: item.Key }}
              handleSelectedOrder={handleSelectedOrder}
              isMobile={isMobile}
            />
          );
        })
      );
    }

    return renderOrders.map((order) => order);
  }

  useEffect(() => {
    setHasError(!!errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    console.log(hasError);
  }, [hasError]);

  return (
    <>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0 12px",
        }}
      >
        <span>Lista de Pedidos</span>
        <ContentButtonRefresh>
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={() => {
              requestItems().finally();
            }}
          >
            <RefreshIcon />
          </IconButton>
        </ContentButtonRefresh>
      </Grid>
      <Paper className="ListOrder">
        {fetching && <Loading />}
        {Array.isArray(listOrder) && renderItems(listOrder)}
      </Paper>
      <SimpleAlert
        message={errorMessage}
        show={hasError}
        setShow={setHasError}
        type="error"
      />
    </>
  );
}
