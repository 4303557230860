import { useCallback, useState } from "react";
import { ProductRepository } from "../repositories/ProductRepository";
import { IProductProps } from "../types";

export function useFetchFindProduct(repository: ProductRepository) {
  const [product, setProduct] = useState<IProductProps>({
    title: "",
    description: "",
    images: [],
    price_list: 0,
    company_id: "",
    active: true,
    category: "",
    subcategory: "",
    code: "",
    price: 0,
    variation: [],
    order: 0,
    created: new Date(),
    last_updated: new Date(),
  });
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<string>();

  const findProduct = useCallback(
    async (uuid: string) => {
      try {
        setFetching(true);

        const resp = await repository.findProduct(uuid);

        setProduct(resp);
        setFetching(false);
      } catch (err: any) {
        setError(err.message);
        setFetching(false);
      }
    },
    [repository]
  );

  return {
    product,
    fetching,
    findProduct,
    errorMessage: error,
  };
}
