import { ClientOrder } from "modules/orders/types/ClientOrder";
import { handleCalcTotalItems } from "modules/orders/utils";
import { formatDecimalToCurrency } from "shared/utils/math";
import {
  DescriptionItems,
  GridItem,
  GridPriceItem,
  ItemAmount,
  ItemName,
  ItemsContent,
  TitleLeft,
} from "./styles";

type IOrderItemsProps = {
  order: ClientOrder;
  sizeTitle?: number;
};

export default function OrderItems(props: IOrderItemsProps) {
  const { order, sizeTitle } = props;

  return (
    <>
      <ItemsContent>
        <TitleLeft sizeTitle={sizeTitle}>
          {handleCalcTotalItems(order.order.items)}
        </TitleLeft>
      </ItemsContent>
      {order.order.items.map((item, index: number) => (
        <GridItem key={index}>
          <DescriptionItems>
            <ItemName>{item.description}</ItemName>
            <ItemAmount>
              {`${item.quantity}x${formatDecimalToCurrency(item.unitValue)}`}
            </ItemAmount>
          </DescriptionItems>
          <GridPriceItem>
            {formatDecimalToCurrency(item.quantity * item.unitValue)}
          </GridPriceItem>
        </GridItem>
      ))}
    </>
  );
}
