import React from 'react';

import TypographyStyled, { StyleFont } from './styles';
import { IMttTypographyProps } from './types';

const MttTypography: React.FC<IMttTypographyProps> = (props: IMttTypographyProps) => {
  const {
    variant, color, display, children, align,
  } = props;

  return (
    <StyleFont>
      <TypographyStyled
        variant={variant}
        color={color}
        display={display}
        align={align}
        >
        { children}
      </TypographyStyled>
    </StyleFont>
  );
};

export default MttTypography;
