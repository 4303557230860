import { useState, useEffect, useContext } from "react";

import { useFetchFindProduct } from "modules/products/hooks/use-fetch-find-product";
import { useValidFormProduct } from "modules/products/hooks/use-valid-form-product";
import { ProductRepository } from "modules/products/repositories/ProductRepository";
import { IProductProps } from "modules/products/types";
import {
  SaveProduct,
  UpdateProduct,
  RemoveProduct,
} from "modules/services/ProductService";

import ImageInput from "shared/components/ImageInput";
import MttInput from "shared/components/MttInput";
import HeaderBack from "shared/components/HeaderBack";
import {
  Container,
  InputContainer,
  CharCounter,
  ContainerForm,
  ContainerInput,
  TitleInput,
  SaveBtn,
} from "./styles";

import { useHistory, useParams } from "react-router";
import AuthContext from "shared/contexts/auth";
import { SimpleAlert } from "shared/components/SimpleAlert";
import { useAlert } from "shared/hooks/useAlert";
import { getDecodedCompanyIdFromToken } from "shared/utils/token";

import { InputCurrency } from "../../../shared/components/FormInputs/InputCurrency";
import axios from "axios";

const maxLength = 74;

type Props = {
  repository: ProductRepository;
};

export const moneyMask = (value: string) => {
  value = value.replace(".", "").replace(",", "").replace(/\D/g, "");

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat("pt-BR", options).format(
    parseFloat(value) / 100
  );

  console.log(result);

  return result;
};

export default function NewProduct({ repository }: Props) {
  const { dispatch } = useContext(AuthContext);
  const { id: productId } = useParams<{ id: string }>();

  const alert = useAlert();
  const history = useHistory();

  const { product, findProduct } = useFetchFindProduct(repository);

  const [productImage, setProductImage] = useState<any>();

  const [currentProduct, setCurrentProduct] = useState<IProductProps>({
    title: "",
    description: "",
    images: [],
    price_list: 0,
    company_id: "",
    active: false,
    category: "",
    subcategory: "",
    code: "",
    price: 0,
    variation: [],
  });

  const [price, setPrice] = useState("");

  const { isValidForm, errors } = useValidFormProduct({
    name: currentProduct.title,
    price: price,
  });

  useEffect(() => {
    if (productId !== undefined && !!findProduct) findProduct(productId);
  }, [findProduct, productId]);

  useEffect(() => {
    setCurrentProduct(product);
    setPrice(String(product.price ?? ""));
    const productImage =
      !!product && Array.isArray(product.images) ? product.images[0] : "";
    setProductImage(productImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const [errorMessageSave, setErrorMessageSave] = useState("");

  useEffect(() => {
    if (!!errorMessageSave) {
      alert.setAlertMessage(errorMessageSave, "error", true);
    } else {
      alert.clearAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessageSave]);

  async function handleOnSave() {
    try {
      if (isValidForm) {
        let new_currentProduct = currentProduct;
        let s3_response: any;

        if (!Array.isArray(new_currentProduct.images))
          new_currentProduct.images = [];

        if (!!file) {
          let formdata = new FormData();
          formdata.append("file", file);
          formdata.append("bucket", "fincommerce-images");
          formdata.append("prefix", "products");

          s3_response = await axios.post(
            "https://payload-dev.zaz.vc/s3/public",
            formdata,
            {
              headers: {
                "x-api-key": "6740ff75-e9f0-4448-8921-54c958d9e504",
              },
            }
          );

          new_currentProduct.images[0] = s3_response.data.url;
        }

        const requestData = {
          ...new_currentProduct,
          _id: productId,
          company_id: getDecodedCompanyIdFromToken() ?? "",
          price: Number(price.replace(",", ".")),
          price_list: Number(price.replace(",", ".")),
        };

        const response = !!productId
          ? await UpdateProduct(
              {
                product: requestData,
              },
              dispatch
            )
          : await SaveProduct(
              {
                product: requestData,
              },
              dispatch
            );

        if (response.Success) {
          setErrorMessageSave("");
          history.push("/products");
        } else {
          const message = !!response.Message
            ? response.Message
            : response.StatusCode.toString();
          setErrorMessageSave(message);
        }
      }
    } catch (error: any) {
      setErrorMessageSave(error.message ?? "");
    }
  }

  async function handleRemove() {
    try {
      if (currentProduct._id) {
        const response = await RemoveProduct(currentProduct._id, dispatch);
        if (response.Success) {
          alert.setAlertMessage(
            "Produto removido com sucesso.",
            "success",
            true
          );
          window.setTimeout(() => {
            history.push("/products");
          }, 1000);
        } else {
          alert.setAlertMessage("Erro ao remover produto.", "error", true);
        }
      }
    } catch (error: any) {
      alert.setAlertMessage(
        error.message ?? "Erro ao remover produto.",
        "error",
        true
      );
    }
  }

  const [descriptionCounter, setDescriptionCounter] = useState(0);

  const counterText = `caractere${
    maxLength - descriptionCounter > 1 ? "s" : ""
  } restante${maxLength - descriptionCounter > 1 ? "s" : ""}`;

  useEffect(
    () => setDescriptionCounter(currentProduct?.description.length ?? 0),
    [currentProduct.description]
  );

  const [file, setFile] = useState<any>();

  return (
    <Container>
      <HeaderBack
        title={currentProduct.title || "Novo Produto"}
        button={() => {
          history.goBack();
        }}
        deleteButton={() => handleRemove()}
      />

      <InputContainer>
        <ContainerForm>
          <ImageInput
            image={productImage}
            setImage={setProductImage}
            setFile={setFile}
          />

          <ContainerInput>
            <TitleInput>Nome</TitleInput>
            <MttInput
              type="text"
              variant="outlined"
              value={currentProduct.title ?? ""}
              error={!!errors.name}
              helperText={errors.name}
              onChange={(e) => {
                setCurrentProduct({
                  ...currentProduct,
                  title: e.target.value,
                });
              }}
              placeholder="Ex: X-Bacon Especial"
            />
          </ContainerInput>

          <ContainerInput>
            <TitleInput>Descrição</TitleInput>
            <MttInput
              type="text"
              variant="outlined"
              value={currentProduct.description}
              multiline
              onChange={(e) => {
                setCurrentProduct({
                  ...currentProduct,
                  description: e.target.value.slice(0, 74),
                });
              }}
            />

            <CharCounter>
              {`${maxLength - descriptionCounter} ${counterText}`}
            </CharCounter>
          </ContainerInput>

          <ContainerInput>
            <TitleInput>Valor</TitleInput>
            <InputCurrency
              value={price}
              setValue={(value) => setPrice(value)}
              error={!!errors.price}
              helperText={errors.price}
            />
          </ContainerInput>
        </ContainerForm>
        <SaveBtn onClick={handleOnSave} disabled={!isValidForm} />
      </InputContainer>
      <SimpleAlert
        message={alert.message}
        type={alert.type}
        show={alert.show}
        setShow={alert.setShow}
      />
    </Container>
  );
}
