import styled from "styled-components";
import MttGrid from "shared/components/MttGrid/MttGrid";

export const OrderInfos = styled(MttGrid)`
  width: 100%;
  height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftInfos = styled(MttGrid)`
  font-size: 14px;
  color: #707070;
`;

export const RightInfos = styled(MttGrid)`
  font-size: 14px;
  color: #000000;
  font-weight: 600;
`;

export const PriceGrid = styled(MttGrid)`
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    fill: #707070;
  }
`;

export const CardGrid = styled(MttGrid)`
  font-size: 14px;
  color: #000000;
  font-weight: bold;

  display: flex;
  align-items: center;
`;

export const GridIcon = styled(MttGrid)`
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
