import { capitalize } from "@material-ui/core";
import { IOrder } from "shared/models/OrderModel";

import { statusNumberToStatusType } from "../utils";
import { ClientOrder } from "../types/ClientOrder";
import { ItemOrderRemote } from "../types/ItemOrderRemote";

function makeDescriptionItem(item: ItemOrderRemote) {
  const { product_name, variation } = item;

  if (!variation || variation === "") return product_name;

  return `${product_name} - ${variation}`;
}

export class ClientOrderFactory {
  public factoryMethod(order: IOrder): ClientOrder {
    const paymentMethod: any = capitalize(order.payment_type);

    return {
      clientName: order?.user.user_name,
      phoneNumber: order?.user.user_phone,
      order: {
        requestNumber: order.order_number,
        status: statusNumberToStatusType(order.status),
        paymentType: paymentMethod,
        // definir chave
        uuid: order._id,
        forecast: order.forecast,
        deliveryTime: {
          value: 0,
          timeType: "Min",
        },
        // não encontrado
        address: order.delivery_address,
        date: order.created,
        totalValue: order.total,
        items: order?.items.map((i: any) => ({
          description: makeDescriptionItem(i),
          unitValue: i.price ?? 0,
          quantity: i.qty ?? 0,
        })),
      },
    };
  }
}
