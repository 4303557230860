import { addSeconds, differenceInSeconds, isFuture } from "date-fns";
import { LogOut, RefreshCompanyToken } from "../AuthService";
import { IMethod } from "./Headers";
import { IBaseResponse, MessageError } from "./Response";
import { getExpirationDateFromToken } from "shared/utils/token";

const apiUrl = process.env.REACT_APP_API_URL;
const headers = new Headers();

const getRequestInit = <TBody>(method: IMethod, body?: TBody): RequestInit => {
  return {
    method,
    headers: headers,
    cache: "no-cache",
    body: JSON.stringify(body),
  };
};

const sendRequest = (
  url: string,
  requestInit: RequestInit
): Promise<Response> => fetch(apiUrl + url, requestInit);

const appendHeader = (prop: string, value: string) => {
  if (headers.has(prop)) {
    headers.set(prop, value);
  } else {
    headers.append(prop, value);
  }
};

const handleError = (resultError: MessageError) => {
  if (resultError && resultError.detail && resultError.detail.errors) {
    const { errors } = resultError.detail;
    return !!errors.length ? errors[0].detail : "";
  }
  return "";
};

const SECONDS_TO_REFRESH_TOKEN = 12;

export async function getToken() {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refresh_token");
  let authorizedToken: string | boolean = false;

  if (!!refreshToken) {
    const dateToExpiration = getExpirationDateFromToken(refreshToken);
    const now = addSeconds(new Date(), SECONDS_TO_REFRESH_TOKEN);

    if (differenceInSeconds(dateToExpiration, now) < SECONDS_TO_REFRESH_TOKEN) {
      const refreshTokenResponse = await getRefreshToken();
      authorizedToken = refreshTokenResponse?.access_token ?? false;
    }
  }

  if (!!token) {
    const dateToExpiration = getExpirationDateFromToken(token);

    if (!isFuture(dateToExpiration)) {
      const refreshTokenResponse = await getRefreshToken();
      authorizedToken = refreshTokenResponse?.access_token ?? false;
    } else {
      authorizedToken = token;
    }
  }

  return authorizedToken;
}

async function removeSignedToken(dispatch: (state: boolean) => void) {
  localStorage.setItem("token", "");
  localStorage.setItem("refresh_token", "");
  localStorage.setItem("type", "");

  await LogOut();
  dispatch(false);
}

async function getRefreshToken() {
  const responseRefreshToken = await RefreshCompanyToken();

  if (!!responseRefreshToken && !!responseRefreshToken.refresh_token) {
    localStorage.setItem("token", responseRefreshToken.access_token);
    localStorage.setItem("refresh_token", responseRefreshToken.refresh_token);

    return responseRefreshToken;
  }
}

export const json = async <TResponse, TRequest = unknown>(
  method: IMethod,
  url: string,
  dispatch: (state: boolean) => void,
  body?: TRequest
): Promise<IBaseResponse<TResponse>> => {
  try {
    appendHeader("Content-Type", "application/json");
    appendHeader("X-API-Key", process.env.REACT_APP_API_KEY ?? "astrazeneca");

    const token = await getToken();

    if (!!token && typeof token === "string") {
      appendHeader("Authorization", token);
    } else {
      await removeSignedToken(dispatch);

      return {
        Data: {} as TResponse,
        StatusCode: 400,
        Success: false,
        Message: "",
      };
    }
    const requestInit = getRequestInit<TRequest>(method, body);
    const result = await sendRequest(url, requestInit);
    if (result.ok) {
      const Data: TResponse = await result.json();
      return {
        Data,
        StatusCode: result.status,
        Success: true,
        Message: "",
      };
    } else {
      const errors: MessageError = await result.json();
      return {
        Data: {} as TResponse,
        StatusCode: result.status,
        Success: false,
        Message: handleError(errors),
      };
    }
  } catch (error) {
    return {
      Data: {} as TResponse,
      StatusCode: 400,
      Success: false,
      Message: "",
    };
  }
};
