import MttGrid from "shared/components/MttGrid/MttGrid";
import styled from "styled-components";

export const FullPage = styled(MttGrid)`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;

  overflow-y: auto;
`;

export const Title = styled(MttGrid)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 16px;
  text-align: center;
  font-size: 20px;
  color: #000000;
  font-weight: bold;

  padding-bottom: 8px;
`;

export const GridButtons = styled(MttGrid)`
  width: 80%;

  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .whatsAppButton {
    width: 48%;
  }

  .phoneButton {
    width: 48%;
  }
`;

export const WhatsButtonGrid = styled(MttGrid).attrs({
  className: "whatsAppButton",
})`
  .MuiButton-root {
    font-size: 12px;
  }
`;

export const PhoneButtonGrid = styled(MttGrid).attrs({
  className: "phoneButton",
})`
  .MuiButton-root {
    font-size: 12px;
  }
`;

export const SubTitle = styled(MttGrid)`
  width: 100%;
  text-align: center;
  font-size: ${window.innerWidth <= 280 ? "12px" : "14px"};
  font-weight: 600;

  padding: 8px 16px;
  padding-bottom: 0;
`;

export const GridStatusOrder = styled(MttGrid)<{ validStatus: boolean }>`
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
  cursor: ${({ validStatus }) => (validStatus ? "pointer" : "initial")};
`;

export const GridOrderValue = styled(MttGrid)`
  width: 100%;
  padding: 20px 16px;
`;

export const GridOrderAddress = styled(MttGrid)`
  width: 100%;
  padding: 0 16px;
`;
