import { ProductRemoteModel } from "../types/ProductRemote";
import { IProductProps } from "../types";

export class ProductRemoteFactory {
  public factoryMethod(order: ProductRemoteModel): IProductProps {
    return {
      _id: order._id,
      title: order.title,
      description: order.description,
      images: order.images ?? [],
      price_list: order.price_list,
      company_id: order.company_id,
      active: order.active,
      category: order.category,
      subcategory: order.subcategory,
      code: order.code,
      price: order.price,
      variation: order.variation,
      order: order.order,
      created: !!order.created ? new Date(order.created) : undefined,
      last_updated: order.last_updated,
    };
  }
}
