import { EStatusOrder } from "modules/orders/types";
import BackgroundColorSelector from "modules/orders/utils/BackgroundColorSelector";
import IconSelector from "modules/orders/utils/IconSelector";
import {
  ContentOrder,
  DescriptionOrder,
  GridDescription,
  IconContent,
  IconGrid,
  TitleOrder,
} from "./styles";

export type IOrderStatusProps = {
  title: EStatusOrder;
  detail: string;
};

export function SelectedStatusDetailsOrder(status: IOrderStatusProps) {
  return (
    <ContentOrder color={BackgroundColorSelector(status.title)} selected={true}>
      <IconGrid>
        <IconContent color={BackgroundColorSelector(status.title)}>
          {IconSelector(status.title)}
        </IconContent>
      </IconGrid>
      <GridDescription>
        <TitleOrder> {status.title} </TitleOrder>
        <DescriptionOrder
          color={BackgroundColorSelector(status.title)}
          selected={true}
        >
          {" "}
          {status.detail}{" "}
        </DescriptionOrder>
      </GridDescription>
    </ContentOrder>
  );
}
