import styled from "styled-components";
import MttGrid from "../MttGrid/MttGrid";

export const Container = styled(MttGrid)`
  padding: 16px;
`;

export const Title = styled(MttGrid)`
  text-align: center;
  margin-bottom: 16px;

  font-size: 20px;
  color: #000000;
  font-weight: bold;
`;

export const Subtitle = styled(MttGrid)`
  text-align: center;
  margin-bottom: 8px;

  font-size: 14px;
  color: #000000;
  font-weight: bold;
`;

export const GridButtons = styled(MttGrid)`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;

  .refuseChoiceButton {
    width: 48%;
  }

  .denyChoiceButton {
    width: 48%;
  }
`;

export const StyledButtonRefuse = styled(MttGrid).attrs({
  className: "refuseChoiceButton",
})``;

export const StyledButtonDeny = styled(MttGrid).attrs({
  className: "denyChoiceButton",
})``;
