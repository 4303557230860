import { json } from "../RequestService";
import {
  ISaveOrderRequest,
  ISingleOrderResponse,
  IUpdateOrderRequest,
  IUpdateOrderResponse,
} from "./types";

import { PaginationType } from "../../../shared/types";

const defaultPath = "orders";

export const GetOrders = async (
  dispatch: (state: boolean) => void,
  query?: PaginationType
) => {
  const action = `${defaultPath}?skip=${query?.skip ?? 0}&limit=${
    query?.limit ?? 100
  }`;
  return await json("GET", action, dispatch);
};

export const GetOrder = async (
  order_id: string,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${order_id}`;
  return await json<ISingleOrderResponse>("GET", action, dispatch);
};

export const SaveOrder = async (
  request: ISaveOrderRequest,
  dispatch: (state: boolean) => void
) => {
  const action = defaultPath;
  return await json<ISingleOrderResponse>("POST", action, dispatch, request);
};

export const RemoveOrder = async (
  order_id: string,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${order_id}`;
  return await json<void>("DELETE", action, dispatch);
};

export const UpdateOrder = async (
  request: IUpdateOrderRequest,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${request.order_id}`;
  return await json<IUpdateOrderResponse>("PATCH", action, dispatch, request);
};

export const GetOrdersByStatus = async <T>(
  dispatch: (state: boolean) => void,
  query?: PaginationType & { request: number[]; company_id?: string }
) => {
  const request = query?.request.join(",");

  let action = `${defaultPath}?skip=${query?.skip ?? 0}&limit=${
    query?.limit ?? 100
  }`;

  if (request) {
    action += `&order_status=${request}`;
  }

  if (!!query?.company_id) {
    action += `&company_id=${query.company_id}`;
  }

  return await json<T>("GET", action, dispatch);
};
