import { useState } from "react";
import { KeyValuePair } from "shared/models/KeyValuePair";
import { EStatusOrder } from "../types";
import { ResumeOrder } from "../types/DetailsOrderRemote";
import { statusTypeTexts } from "../types/typesTexts";

export function useFilterListOrders() {
  const defaultFilter: Array<KeyValuePair<boolean, EStatusOrder>> = [
    {
      Key: false,
      Value: statusTypeTexts.PENDING,
    },
    {
      Key: false,
      Value: statusTypeTexts.IN_PREPARATION,
    },
    {
      Key: false,
      Value: statusTypeTexts.READY,
    },
    {
      Key: false,
      Value: statusTypeTexts.ON_WAY,
    },
    {
      Key: false,
      Value: statusTypeTexts.DELIVERED,
    },
    {
      Key: false,
      Value: statusTypeTexts.REFUSED,
    },
  ];

  const [filter, setFilter] = useState<
    Array<KeyValuePair<boolean, EStatusOrder>>
  >(defaultFilter);

  function filterUpdate(index: number) {
    filter.splice(index, 1, {
      Key: !filter[index].Key,
      Value: filter[index].Value,
    });

    setFilter([...filter]);
  }

  function filterReset() {
    setFilter([...defaultFilter]);
  }

  function filterOrder(orders: Array<ResumeOrder>) {
    const aux: Array<KeyValuePair<number, ResumeOrder>[]> = [
      [],
      [],
      [],
      [],
      [],
      [],
    ];

    orders.forEach((order, index) => {
      switch (order.status) {
        case statusTypeTexts.PENDING: {
          return aux[0].push({
            Key: index,
            Value: order,
          });
        }
        case statusTypeTexts.IN_PREPARATION: {
          return aux[1].push({
            Key: index,
            Value: order,
          });
        }
        case statusTypeTexts.READY: {
          return aux[2].push({
            Key: index,
            Value: order,
          });
        }
        case statusTypeTexts.ON_WAY: {
          return aux[3].push({
            Key: index,
            Value: order,
          });
        }
        case statusTypeTexts.DELIVERED: {
          return aux[4].push({
            Key: index,
            Value: order,
          });
        }
        case statusTypeTexts.REFUSED: {
          return aux[5].push({
            Key: index,
            Value: order,
          });
        }
      }
    });

    return aux;
  }

  return {
    filterReset,
    filterUpdate,
    filter,
    filterOrder,
  };
}
