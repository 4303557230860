import MttGrid from "shared/components/MttGrid/MttGrid";
import styled from "styled-components";

export const FullPage = styled(MttGrid)`
  width: 100vw;
  max-height: 100vh;

  overflow-y: auto;

  padding-bottom: 16px;
`;

export const ContainerOrderTypes = styled(MttGrid)`
  width: 100%;

  padding: 0 16px;
`;

export const Title = styled(MttGrid)`
  font-family: 16px;
  font-weight: bold;

  padding: 16px;
`;

export const ContentOrder = styled(MttGrid)<{
  selected?: boolean;
  color?: string;
  validStatus?: boolean;
}>`
  width: 100%;
  color: ${(props) => (props.selected ? "#FFFFFF" : "#000000")};
  background-color: ${(props) =>
    props.selected ? `${props.color}` : "#F3F3F3"};

  margin: 4px 0;
  border-radius: 4px;
  padding: 16px;

  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 16px;
  cursor: pointer;
`;

export const GridDescription = styled(MttGrid)`
  width: 100%;
`;

export const IconContent = styled(MttGrid)<{ color?: string }>`
  width: 40px;
  height: 40px;

  background-color: #fff;
  border-radius: 50%;

  color: ${(props) => props.color};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconGrid = styled(MttGrid)`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleOrder = styled(MttGrid)`
  width: 100%;
  font-weight: bold;
  font-size: 16px;
`;

export const DescriptionOrder = styled(MttGrid)<{ selected?: boolean }>`
  width: 100%;
  font-size: 16px;

  color: ${(props) => (props.selected ? "#FFFFFF" : "#707070")};
`;
