import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";

import { UpdateCompany } from "modules/services/CompanyService";
import { useFetchFindCompany } from "modules/store-information/hooks/use-fetch-find-company";
import { CompanyRepository } from "modules/store-information/repositories/CompanyRepository";
import { ICompanyProps } from "modules/store-information/types";
import HeaderBack from "shared/components/HeaderBack";
import ImageInput from "shared/components/ImageInput";
import MttInput from "shared/components/MttInput";
import PhoneInput from "shared/components/FormInputs/PhoneInput";
import { SimpleAlert } from "shared/components/SimpleAlert";
import AuthContext from "shared/contexts/auth";
import { useAlert } from "shared/hooks/useAlert";
import { getDecodedCompanyIdFromToken } from "shared/utils/token";
import {
  ContainerDescription,
  ContainerForm,
  ContainerInput,
  SaveButton,
  TitleInput,
} from "./styles";

type Props = {
  repository: CompanyRepository;
};

export default function AboutStore({ repository }: Props) {
  const { dispatch } = useContext(AuthContext);
  const alert = useAlert();
  const history = useHistory();

  const { company, findCompany } = useFetchFindCompany(repository);

  const [currentCompany, setCurrentCompany] = useState<ICompanyProps>({
    _id: "",
    contact_phone: "",
    contact_email: "",
    contact_name: "",
    contact_nickname: "",
    company_id: "",
    company_corporate_name: "",
    company_business_name: "",
    company_description: "",
    company_image: "",
    company_phone: "",
    company_email: "",
    company_pix: 0,
    company_hashtag: "",
    hashtag_updated: false,
    welcome_message: "",
    freight: 0,
    company_status: 0,
    company_address: {
      street: "",
      number: "",
      neighborhood: "",
      city: "",
      state: "",
      zip_code: "",
      location: {
        lat: 0,
        long: 0,
      },
    },
    owner_id: "",
    created: new Date(),
    last_updated: new Date(),
  });

  useEffect(() => {
    findCompany(getDecodedCompanyIdFromToken() ?? "");
  }, [findCompany]);

  useEffect(() => {
    setCurrentCompany(company);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const uploadImage = async () => {
    if (!!file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", "fincommerce-images");
      formData.append("prefix", "store-information");

      const s3_response = await axios.post(
        "https://payload-dev.zaz.vc/s3/public",
        formData,
        {
          headers: {
            "x-api-key": "6740ff75-e9f0-4448-8921-54c958d9e504",
          },
        }
      );

      return s3_response.data.url ?? "";
    }

    return currentCompany.company_image ?? "";
  };

  const updateStore = async () => {
    try {
      const companyPhone = !!currentCompany.company_phone
        ? String(currentCompany.company_phone)
        : "";

      const imageUrl = await uploadImage();

      const requestData = {
        ...currentCompany,
        company_phone: companyPhone.replace(/\D/gi, ""),
        company_image: imageUrl,
      };

      const response = await UpdateCompany(
        {
          company: requestData,
        },
        dispatch
      );

      if (response.Success) {
        alert.setAlertMessage("Loja atualizada com sucesso.", "success", true);
      } else {
        alert.setAlertMessage(
          response.Message ?? "Erro ao atualizar loja.",
          "error",
          true
        );
      }
    } catch (error: any) {
      console.log("error", error.message);

      alert.setAlertMessage(error.message, "error", true);
    }
  };

  const [image, setImage] = useState(currentCompany.company_image);
  const [file, setFile] = useState<any>();

  useEffect(() => {
    setImage(currentCompany.company_image);
  }, [currentCompany.company_image]);

  return (
    <>
      <HeaderBack
        title="Sobre a loja"
        button={() => {
          history.goBack();
        }}
      />

      <ContainerForm>
        <ContainerInput>
          <TitleInput>Logo</TitleInput>

          <ImageInput
            image={image}
            setImage={(company_image) => {
              console.log("image", image);
              setImage(company_image);
            }}
            setFile={setFile}
          />
        </ContainerInput>

        <ContainerInput>
          <TitleInput>Nome</TitleInput>
          <MttInput
            type="text"
            variant="outlined"
            value={currentCompany.company_business_name}
            onChange={(e) => {
              setCurrentCompany({
                ...currentCompany,
                company_business_name: e.target.value,
              });
            }}
          />
        </ContainerInput>

        <ContainerInput>
          <TitleInput>Atalho(hashtag)</TitleInput>
          <MttInput
            type="text"
            variant="outlined"
            value={currentCompany.company_hashtag ?? ""}
            onChange={(e) => {
              setCurrentCompany({
                ...currentCompany,
                company_hashtag: e.target.value,
              });
            }}
          />
        </ContainerInput>

        <ContainerInput>
          <TitleInput>WhatsApp</TitleInput>
          <PhoneInput
            className="phone-input"
            value={currentCompany.company_phone ?? ""}
            onChange={(e: any) => {
              setCurrentCompany({
                ...currentCompany,
                company_phone: e.target.value,
              });
            }}
          />
        </ContainerInput>

        <ContainerDescription>
          <TitleInput>Descrição</TitleInput>
          <MttInput
            type="text"
            variant="outlined"
            value={currentCompany.company_description ?? ""}
            onChange={(e) => {
              setCurrentCompany({
                ...currentCompany,
                company_description: e.target.value,
              });
            }}
            placeholder="Insira detalhes da loja ..."
          />
        </ContainerDescription>

        <ContainerDescription>
          <TitleInput>Mensagem de boas-vindas</TitleInput>
          <MttInput
            type="text"
            variant="outlined"
            value={currentCompany.welcome_message ?? ""}
            onChange={(e) => {
              setCurrentCompany({
                ...currentCompany,
                welcome_message: e.target.value,
              });
            }}
            placeholder={`Ex: Seja bem-vindo a Pizzaria Donatello, agradecemos sua preferência!`}
          />
        </ContainerDescription>

        <SaveButton
          onClick={() => {
            updateStore();
          }}
        />
        <SimpleAlert
          message={alert.message}
          type={alert.type}
          show={alert.show}
          setShow={alert.setShow}
        />
      </ContainerForm>
    </>
  );
}
