import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";

import {
  ContainerOrderTypes,
  ContentOrder,
  DescriptionOrder,
  FullPage,
  GridDescription,
  IconContent,
  IconGrid,
  Title,
  TitleOrder,
} from "./styles";

import { OrderRepository } from "modules/orders/repositories/OrderRepository";
import { EStatusOrder } from "modules/orders/types";

import HeaderBack from "shared/components/HeaderBack";
import BackgroundColorSelector from "modules/orders/utils/BackgroundColorSelector";
import { orderStatusMessages } from "pages/orders/DetailsOrder/SelectedStatus/strings";
import { useFetchFindOrder } from "modules/orders/hooks/use-fetch-find-order";
import IconSelector from "modules/orders/utils/IconSelector";
import { useAlert } from "shared/hooks/useAlert";
import { SimpleAlert } from "shared/components/SimpleAlert";

type Props = { repository: OrderRepository };

export default function StatusOrderPage({ repository }: Props) {
  const { id: orderId } = useParams<{ id: string }>();
  const history = useHistory();
  const { order, findOrder } = useFetchFindOrder(repository);
  const alert = useAlert();

  useEffect(() => {
    if (orderId !== undefined && !!findOrder) findOrder(orderId);
  }, [findOrder, orderId]);

  function handleGoBack() {
    history.goBack();
  }

  async function handleUpdateStatus(statusType: EStatusOrder) {
    try {
      await repository.updateStatus(orderId, statusType);
      alert.setAlertMessage("Status atualizado", "success", true);

      setTimeout(() => {
        history.goBack();
      }, 1000);
    } catch (err: any) {
      alert.setAlertMessage(err.message, "success", true);
    }
  }

  return (
    <>
      <FullPage>
        <HeaderBack button={handleGoBack} title="Status do pedido" />
        <Title>Selecione o novo status</Title>
        <ContainerOrderTypes>
          {orderStatusMessages
            .filter((item) => item.title !== "Pendente")
            .map((item, index) => (
              <ContentOrder
                key={`${index}-${item.title}`}
                color={BackgroundColorSelector(item.title)}
                selected={item.title === order?.order.status}
                onClick={() => {
                  handleUpdateStatus(item.title);
                }}
              >
                <IconGrid>
                  <IconContent color={BackgroundColorSelector(item.title)}>
                    {IconSelector(item.title)}
                  </IconContent>
                </IconGrid>
                <GridDescription>
                  <TitleOrder> {item.title} </TitleOrder>
                  <DescriptionOrder
                    selected={item.title === order?.order.status}
                    color={BackgroundColorSelector(item.title)}
                  >
                    {item.detail}
                  </DescriptionOrder>
                </GridDescription>
              </ContentOrder>
            ))}
        </ContainerOrderTypes>
      </FullPage>
      <SimpleAlert
        message={alert.message}
        type={alert.type}
        show={alert.show}
        setShow={alert.setShow}
      />
    </>
  );
}
