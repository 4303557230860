import { ReactComponent as MoneyIcon } from "assets/svgs/money.svg";
import { ReactComponent as CreditCardIcon } from "assets/svgs/credit_card.svg";
import { ReactComponent as PixIcon } from "assets/svgs/pix.svg";
import { EPaymentType } from "modules/orders/types";

export function getPaymentIcon(payment: EPaymentType) {
  switch (payment) {
    case "Pix":
      return <PixIcon />;
    case "Cartão Credito":
    case "Cartão Debito":
      return <CreditCardIcon />;
    default:
      return <MoneyIcon />;
  }
}
