import Typography from '@material-ui/core/Typography/Typography';
import styled from 'styled-components';
import MttGrid from '../MttGrid/MttGrid';

const TypographyStyled = styled(Typography)``;

export default TypographyStyled;


export const StyleFont = styled(MttGrid)`
  .MuiTypography-root{
    font-family: 'Montserrat', sans-serif;
  }
`;