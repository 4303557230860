import { useState } from "react";

export function useActionHandler() {
  const [showRefuseModal, setShowRefuseModal] = useState(false);
  const [showRefusedModal, setShowRefusedModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);

  return {
    showRefuseModal,
    setShowRefuseModal,
    showRefusedModal,
    setShowRefusedModal,
    showAcceptModal,
    setShowAcceptModal,
  };
}
