import styled from "styled-components";
import palette from "shared/theme/palette";
import MttGrid from "../MttGrid/MttGrid";

export const Header = styled(MttGrid)`
  width: 100%;

  display: grid;
  grid-template-columns: 40px 1fr 1fr;
  grid-gap: 16px;

  padding: 16px;
  padding-bottom: 16px;
`;

export const GridIcon = styled(MttGrid)`
  width: 40px;
  height: 40px;
  color: ${palette.gray};
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${palette.lightGray};

  border-radius: 50%;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;

export const Title = styled(MttGrid)`
  display: flex;
  align-items: center;
  font-size: ${window.innerWidth <= 280 ? "18px" : "20px"};
  font-weight: bold;
  white-space: nowrap;
`;
