import MttGrid from "shared/components/MttGrid/MttGrid";
import styled from "styled-components";

export const ItemsContent = styled(MttGrid)`
  padding: 8px 0;
`;

export const TitleLeft = styled(MttGrid)<{ sizeTitle?: number }>`
  font-size: ${(props) => (props.sizeTitle ? `${props.sizeTitle}px` : "20px")};
  color: #000000;
  font-weight: bold;
`;

export const GridItem = styled(MttGrid)`
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;

  border-bottom: 1px solid #00000020;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
  }
`;

export const ItemImage = styled(MttGrid)`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DescriptionItems = styled(MttGrid)`
  width: 100%;
`;

export const ItemName = styled(MttGrid)`
  font-size: 16px;
  color: #000000;
  font-weight: 500;
`;

export const ItemAmount = styled(MttGrid)`
  font-size: 14px;
  color: #707070;
  font-weight: 400;
`;

export const GridPriceItem = styled(MttGrid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-weight: bold;
  font-size: 16px;
`;
