import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Close";
import MoodIcon from "@material-ui/icons/Mood";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { EStatusOrder } from "modules/orders/types";

export default function IconSelector(status: EStatusOrder) {
  switch (status) {
    case "Pendente":
      return <ShoppingBasketIcon />;
    case "Em Preparo":
      return <ScheduleIcon />;
    case "A Caminho":
      return <MotorcycleIcon />;
    case "Entregue":
      return <CheckCircleIcon />;
    case "Pronto":
      return <MoodIcon />;
    case "Recusado":
      return <CancelIcon />;
    default:
      break;
  }
}
