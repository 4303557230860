import { fromUnixTime, isFuture } from "date-fns";
import jwt_decode from "jwt-decode";
import { zonedTimeToUtc } from "date-fns-tz";

export function isValidToken(token: string | undefined | null) {
  if (typeof token !== "string" || token === "") {
    return false;
  }

  const dateToExpiration = getExpirationDateFromToken(token);

  return isFuture(dateToExpiration);
}

export function getExpirationDateFromToken(token: string) {
  const { exp }: any = jwt_decode(token);
  const dateToExpiration = fromUnixTime(exp);

  return zonedTimeToUtc(dateToExpiration, "America/Sao_Paulo");
}

export function getDecodedCompanyIdFromToken(): string | undefined {
  const token = localStorage.getItem("token");

  if (!!token) {
    const decoded: any = jwt_decode(token);

    return decoded.sub;
  }

  return "";
}
