import MttGrid from "shared/components/MttGrid/MttGrid";
import styled from "styled-components";

export const FullPage = styled(MttGrid)`
  width: 100vw;
  height: 100vh;
  background-color: #00000030;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Container = styled(MttGrid)`
  width: 100%;
  min-height: 80%;
  max-height: 100%;

  overflow-y: auto;

  border-radius: 4px 4px 0 0;

  padding: 16px;

  position: absolute;
  bottom: 0;
  left: 0;

  background-color: #ffffff;
`;

export const Title = styled(MttGrid)`
  width: 100%;
  text-align: center;

  padding: 16px 0;

  font-size: 20px;
  font-weight: bold;
`;

export const TitleResponsive = styled(MttGrid)`
  width: 100%;
  text-align: center;

  padding: 16px 0;

  font-size: ${window.innerWidth <= 280 ? "16px" : "20px"};
  font-weight: bold;
`;

export const GridTimeDelivery = styled(MttGrid)`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiOutlinedInput-input {
    background-color: #f3f3f3;
  }

  .MuiInputBase-fullWidth {
    width: 95%;
  }

  .MuiGrid-root {
    width: 95%;
  }
  .MuiFormControl-root {
    width: 100%;
  }
`;

export const GridConfirmButton = styled(MttGrid).attrs({
  className: "aceptChoiceButton",
})`
  padding-top: 8px;
`;

export const SError = styled.div`
  color: #f44336;
  padding: 10px 0;
`;
