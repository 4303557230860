import React from 'react';

import Dialog from '@material-ui/core/Dialog/Dialog';
import { IMttDialogProps } from './types';

const MttDialog: React.FC<IMttDialogProps> = (props: IMttDialogProps) => {
  const {
    open, onClose, children, style,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={style}
    >
      {children}
    </Dialog>
  );
};

export default MttDialog;
