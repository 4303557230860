import { LoadingContent } from "./styles";

import { CircularProgress } from "@material-ui/core";

export function Loading() {
  return (
    <LoadingContent>
      <CircularProgress />
    </LoadingContent>
  );
}
