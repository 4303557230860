import { createGlobalStyle, css } from "styled-components";
import palette from "./palette";

const styleDefault = css`
  width: 100%;
  height: 100%;
  text-align: center;
  text-transform: none;
  font-size: ${window.innerWidth <= 280 ? "10px" : "15px"};
  font-weight: 600;
  box-shadow: none;
  border: 2px solid;
  border-radius: 8px;
`;

export default createGlobalStyle`

	*{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
    outline: 0;

    .MuiTypography-root{
      font-family: 'Montserrat', sans-serif;
    }
	}
	
  body {
    min-width: 320px;
  }

	body, input, button{
		font-family: 'Montserrat', sans-serif;
		font-size: 16px;
  }

  .App > .MuiGrid-root {
    min-width: 360px;
  }
  
	.pendingButton{
    .MuiButton-colorInherit{
      width: 100%;
			${styleDefault};
      background-color: ${palette.white};
      color: ${palette.gray};
			border-color: ${palette.pending};

      :active {
        background-color: ${palette.pending};
				color:${palette.white};
      }
    }
		.MuiButton-root{
      padding: 0;
    }
  }

  .preparationButton{
    .MuiButton-colorInherit{
      width: 100%;
			${styleDefault};
      background-color: ${palette.white};
      color: ${palette.gray};
			border-color: ${palette.preparation};

      :active {
        background-color: ${palette.preparation};
				color:${palette.white};
      }
    }
		.MuiButton-root{
      padding: 0;
    }
  }

  .doneButton{
    .MuiButton-colorInherit{
      width: 100%;
			${styleDefault};
      background-color: ${palette.white};
      color: ${palette.gray};
			border-color: ${palette.done};

      :active {
        background-color: ${palette.done};
				color:${palette.white};
      }
    }
		.MuiButton-root{
      padding: 0;
    }
  }

  .progressButton{
    .MuiButton-colorInherit{
      width: 100%;
			${styleDefault};
      background-color: ${palette.white};
      color: ${palette.gray};
			border-color: ${palette.progress};
		
      :active {
        background-color: ${palette.progress};
				color:${palette.white};
      }
    }
		.MuiButton-root{
      padding: 0;
    }
  }

	.deliveredButton{
    .MuiButton-colorInherit{
      width: 100%;
			${styleDefault};
      background-color: ${palette.white};
      color: ${palette.gray};
			border-color: ${palette.delivered};
			
      :active {
        background-color: ${palette.delivered};
				color:${palette.white};
      }
    }
		.MuiButton-root{
      padding: 0;
    }
  }	
	
  .refusedButton{
    .MuiButton-colorInherit{
      width: 100%;
			${styleDefault};
      background-color: ${palette.white};
      color: ${palette.gray};
			border-color: ${palette.refused};
			

      :active {
        background-color: ${palette.refused};
				color:${palette.white};
      }
    }
		.MuiButton-root{
      padding: 0;
    }
  }

  .cleanButton{
    .MuiButton-root{
      padding: 0;
    }
    .MuiButton-colorInherit{
 
			${styleDefault};
      background-color: ${palette.refused};
      color: ${palette.white};
			border-color: ${palette.refused};
			
    }
		.MuiButton-root{
      padding: 0;
    }
	
  }
	
	.aceptChoiceButton{
    .MuiButton-root{
      padding: 0;
    }
    .MuiButton-colorInherit{
      width: 100%;
      height: 51px;
      text-transform: capitalize;
      background-color: ${palette.delivered};
      color: ${palette.white};
      :hover{
        background-color: ${palette.delivered};
      }
    }
  }
	
  .denyChoiceButton{
    .MuiButton-root{
      padding: 0;
    }
    .MuiButton-colorInherit{
      width: 100%;
      height: 51px;
      text-transform: capitalize;
      background-color: ${palette.pending};
      color: ${palette.white};
      :hover{
        background-color: ${palette.pending};
      }
    }
  }

  .refuseChoiceButton{
    .MuiButton-colorInherit{
      width: 100%;
      height: 51px;
      text-transform: capitalize;
      background-color: ${palette.refused};
      color: ${palette.white};
      :hover{
        background-color: ${palette.refused};
      }
    }
  }

  .grayButton{
    padding: 0;
    .MuiButton-colorInherit{
      width: 100%;
      height: 51px;
      text-transform: capitalize;
      background-color: ${palette.refused};
      color: ${palette.white};
      :hover{
        background-color: ${palette.refused};
      }
    }
  }
  
  .phoneButton{
    .MuiButton-colorInherit{
      width: 100%;
      text-transform: capitalize;
      background-color: ${palette.phone};
      color: ${palette.white};
      :hover{
        background-color: ${palette.phone};
      }
    }
  }
  
  .whatsAppButton{
    .MuiButton-colorInherit{
      width: 100%;
      text-transform: capitalize;
      background-color: ${palette.whatsApp};
      color: ${palette.white};
      :hover{
        background-color: ${palette.whatsApp};
      }
    }
  }

 .storeTitle{
    font-size: 20px;
    font-weight: bold;
 }
`;
