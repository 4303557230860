import { useCallback, useState } from "react";
import { getDecodedCompanyIdFromToken } from "shared/utils/token";
import { ProductRepository } from "../repositories/ProductRepository";
import { IProductProps } from "../types";

export function useFetchFindProducts(repository: ProductRepository) {
  const [products, setProducts] = useState<IProductProps[]>();

  const [fetching, setFetching] = useState(false);

  const fetchProducts = useCallback(async () => {
    try {
      setFetching(true);
      const resp = await repository.findProducts({
        company_id: getDecodedCompanyIdFromToken() ?? "",
      });

      resp.sort(function (a: any, b: any) {
        return a.order - b.order;
      });

      setProducts(resp);
      setFetching(false);
    } catch (err) {
      setFetching(false);
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    fetching,
    fetchProducts,
    products,
  };
}
