import React from 'react';

import Grid from '@material-ui/core/Grid/Grid';

import { IGridProps } from './types';

const MttGrid: React.FC<IGridProps> = (props: IGridProps) => {
	const { children } = props;

	return <Grid {...props}>{children}</Grid>;
};

export default MttGrid;