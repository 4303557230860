import { capitalize } from "@material-ui/core";
import { dateToAmericaSaoPaulo } from "shared/utils/date";
import { statusNumberToStatusType as getStatusTypeFromNumber } from "../utils";
import { ResumeOrder, ResumeOrderRemote } from "../types/DetailsOrderRemote";

export function resumeOrderFactory(
  detailsOrder: ResumeOrderRemote
): ResumeOrder {
  const paymentMethod: any = capitalize(detailsOrder.payment_type);

  return {
    companyId: detailsOrder.company_id,
    deliveryAddress: detailsOrder.delivery_address,
    identifier: detailsOrder._id, // UUID
    userName: detailsOrder.user.user_name,
    numberOfItems: detailsOrder.items.length,
    total: detailsOrder.total,
    created: new Date(detailsOrder.created), // date
    forecast: dateToAmericaSaoPaulo(detailsOrder.forecast), // date
    paymentType: paymentMethod, // OrderPaymentType
    status: getStatusTypeFromNumber(detailsOrder.status), // OrderStatusType
    requestNumber: detailsOrder.order_number,
  };
}
