const palette = Object.freeze({
	green: '#009400',
	pending: '#eb0006',
	preparation: '#ffb200',
	done: '#006fff',
	progress: '#3a2780',
	delivered: '#009400',
	refused: '#808080',
	gray: '#707070',
	lightGray: '#f3f3f3',
	darkGray: '#7d7d7d',
	white: '#ffffff',
	black: '#000000',
	whatsApp: '#075E54',
	phone: '#25D366',
	purple: '#483B8A',
});

export default palette;