import { useCallback, useEffect, useState } from "react";
import { OrderRepository } from "../repositories/OrderRepository";
import { ResumeOrder } from "../types/DetailsOrderRemote";
import { getDecodedCompanyIdFromToken } from "shared/utils/token";

export function useFetchFindOrdersByStatus(repository: OrderRepository) {
  const [orders, setOrders] = useState<ResumeOrder[]>([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<string>();

  const fetchFindOrdersByStatus = useCallback(async () => {
    try {
      setFetching(true);
      const resp = await repository.findOrderByStatus({
        request: [0, 1, 2, 3, 4, 5],
        company_id: getDecodedCompanyIdFromToken(),
      });

      setOrders(resp);
      setFetching(false);
      setError(undefined);
    } catch (error: any) {
      setFetching(false);
      setError(error.message);
    }
  }, [repository]);

  useEffect(() => {
    fetchFindOrdersByStatus();
  }, [fetchFindOrdersByStatus]);

  return {
    orders,
    fetchFindOrders: fetchFindOrdersByStatus,
    fetching,
    errorMessage: error,
  };
}
