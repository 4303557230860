import { GetProduct, GetProducts } from "modules/services/ProductService";
import { ProductPaginationType } from "modules/services/ProductService/types";
import { ProductRemoteFactory } from "../factories/ProductRemoteFactory";
import { ProductRemoteModel } from "../types/ProductRemote";
import { IProductProps } from "../types";

export class ProductRepository {
  constructor(
    private readonly remoteFactory: ProductRemoteFactory,
    private readonly dispatch: (state: boolean) => void
  ) {}

  async findProducts(query?: ProductPaginationType) {
    const resp: any = await GetProducts(this.dispatch, query);

    const self = this;
    const data: IProductProps[] = resp.Data.data.map(
      (product: ProductRemoteModel) => self.remoteFactory.factoryMethod(product)
    );

    return data;
  }

  async findProduct(uuid: string) {
    const resp: any = await GetProduct(uuid, this.dispatch);
    return this.remoteFactory.factoryMethod(resp.Data.data);
  }
}
