import styled from "styled-components";
import palette from "shared/theme/palette";
import MttButton from "../MttButton/MttButton";
import MttGrid from "../MttGrid/MttGrid";

export const Container = styled(MttGrid)`
  background: ${palette.lightGray};
  position: relative;
  height: 171px;
  width: 100%;

  .MuiButton-root {
    background: ${palette.gray};
    border-radius: 50px;
    bottom: 8px;
    height: 40px;
    width: 135px;
    position: absolute;

    .MuiSvgIcon-root {
      fill: ${palette.white};
      height: 18px;
      width: 20px;
    }
  }
`;

export const Input = styled.input`
  color: transparent;
  position: absolute;
  z-index: 200;
  height: 100%;
  width: 100%;

  ::-webkit-file-upload-button {
    display: none;
  }
`;

export const InputLabel = styled.label`
  align-items: center;
  color: ${palette.gray};
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  cursor: pointer;

  .MuiSvgIcon-root {
    fill: ${palette.gray};
    height: 45px;
    width: 50px;
  }
`;

export const ImagePreview = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const ReplaceBtn = styled(MttButton).attrs({
  children: "Substituir foto",
})``;
