import styled from "styled-components";

import MttGrid from "shared/components/MttGrid/MttGrid";
import palette from "shared/theme/palette";

export const Container = styled(MttGrid)`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const Header = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${palette.lightGray};
  box-shadow: 0 0 4px 0 ${palette.gray};
`;

export const GridHeader = styled(MttGrid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 8px 0 8px 0;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${palette.gray};
`;

export const ContainerName = styled.div``;