import PhoneIcon from "@material-ui/icons/Phone";
import { useCallback, useEffect, useState } from "react";
import { format, formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useHistory, useParams } from "react-router";

import { OrderRepository } from "modules/orders/repositories/OrderRepository";
import { useFetchFindOrder } from "modules/orders/hooks/use-fetch-find-order";
import WhatsAppIcon from "assets/icons/WhatsApp";
import HeaderBack from "shared/components/HeaderBack";
import MttButton from "shared/components/MttButton/MttButton";
import OrderAddress from "modules/orders/components/OrderAddress";
import OrderHour from "modules/orders/components/OrderHour";

import { orderStatusMessages } from "pages/orders/DetailsOrder/SelectedStatus/strings";
import { dateToAmericaSaoPaulo } from "shared/utils/date";

import {
  Title,
  SubTitle,
  FullPage,
  GridButtons,
  PhoneButtonGrid,
  WhatsButtonGrid,
  GridStatusOrder,
  GridOrderValue,
  GridOrderAddress,
} from "./styles";
import { SelectedStatusDetailsOrder } from "./SelectedStatus";
import OrderItems from "modules/orders/components/OrderItens";
import { createCodeFromOrderNumber } from "shared/utils/string";

export type ForecastOrderDetail = {
  estimatedTime: string;
  leftTime: string;
};

export const deliveryTimeToForecastOrder = (
  forecast: Date
): ForecastOrderDetail => {
  const utcForecast = dateToAmericaSaoPaulo(forecast);

  return {
    estimatedTime: format(utcForecast, "HH:mm", { locale: ptBR }),
    leftTime: formatDistance(dateToAmericaSaoPaulo(new Date()), utcForecast, {
      locale: ptBR,
    }),
  };
};

type Props = {
  repository: OrderRepository;
};

export default function DetailsOrderPage({ repository }: Props) {
  const { id: orderId } = useParams<{ id: string }>();

  const { order, findOrder } = useFetchFindOrder(repository);

  useEffect(() => {
    if (orderId !== undefined && !!findOrder) findOrder(orderId);
  }, [findOrder, orderId]);

  const orderStatus = {
    Key: -1,
    Value: order.order.status,
  };

  const [selectedStatus, setSelectedStatus] = useState<JSX.Element>();

  function handleShowSelectedStatus(item: JSX.Element) {
    setSelectedStatus(item);
  }

  const showStatusTypes = useCallback(() => {
    orderStatusMessages.forEach((status) => {
      if (status.title === orderStatus.Value) {
        handleShowSelectedStatus(<SelectedStatusDetailsOrder {...status} />);
      }
    });
  }, [orderStatus.Value]);

  useEffect(() => {
    showStatusTypes();
  }, [showStatusTypes]);

  const [timeLeft, setTimeLeft] = useState<ForecastOrderDetail>();

  useEffect(() => {
    if (!!order.order.forecast)
      setTimeLeft(deliveryTimeToForecastOrder(order.order.forecast));
  }, [order]);

  const history = useHistory();

  function isValidStatusToChange(): boolean {
    const { status: currentStatus } = order.order;
    return !(currentStatus === "Entregue" || currentStatus === "Recusado");
  }

  return (
    <FullPage>
      <HeaderBack title="Detalhes do pedido" button={() => history.goBack()} />
      <Title>{`${order.clientName} • ${createCodeFromOrderNumber(
        order.order.requestNumber ?? ""
      )}`}</Title>
      <GridButtons>
        <WhatsButtonGrid>
          <MttButton
            variant="contained"
            color="inherit"
            startIcon={<WhatsAppIcon color="#FFF" />}
            onClick={() => {
              window.open(
                `https://api.whatsapp.com/send?phone=${
                  /$55/gi.test(order.phoneNumber)
                    ? order.phoneNumber
                    : "55" + order.phoneNumber
                }`
              );
            }}
          >
            conversar
          </MttButton>
        </WhatsButtonGrid>
        <PhoneButtonGrid>
          <MttButton
            variant="contained"
            color="inherit"
            startIcon={<PhoneIcon />}
            onClick={() => {
              window.open(`tel:${order.phoneNumber}`);
            }}
          >
            telefonar
          </MttButton>
        </PhoneButtonGrid>
      </GridButtons>
      <SubTitle>
        {`Previsão de entrega: ${timeLeft?.estimatedTime} ${
          window.innerWidth > 350 ? `(${timeLeft?.leftTime})` : ""
        }`}
      </SubTitle>
      {window.innerWidth <= 350 ? (
        <SubTitle>{`(${timeLeft?.leftTime})`}</SubTitle>
      ) : (
        <></>
      )}

      <GridStatusOrder
        validStatus={isValidStatusToChange()}
        onClick={() => {
          if (isValidStatusToChange())
            history.push(`/orders/${orderId}/status`);
        }}
      >
        {selectedStatus || "selecione"}
      </GridStatusOrder>

      <GridOrderValue>
        <OrderHour
          valorTotal={order.order.totalValue}
          payment={order.order.paymentType}
          onlyValue
          date={order.order.date}
        />
      </GridOrderValue>
      <GridOrderAddress>
        <OrderAddress address={order.order.address} />
      </GridOrderAddress>
      <GridOrderAddress>
        <OrderItems order={order} />
      </GridOrderAddress>
    </FullPage>
  );
}
