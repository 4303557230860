import styled from "styled-components";
import MttButton from "shared/components/MttButton/MttButton";
import MttGrid from "shared/components/MttGrid/MttGrid";
import palette from "shared/theme/palette";

export const Container = styled(MttGrid)`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100vw;

  .jnyhvc {
    background-color: ${palette.lightGray};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
  height: 88vh;
  width: 100vw;

  button {
    background: ${palette.green};
    color: ${palette.white};
    text-transform: none;
  }
`;

export const SaveBtn = styled(MttButton).attrs({
  fullWidth: true,
  children: "Salvar",
})`
  height: 51px;'
`;

export const CharCounter = styled.div`
  justify-content: flex-end;
  display: flex;
  color: ${palette.darkGray};
  font-weight: 600;
  font-size: 0.8em;
  margin-top: 4px;
`;

export const ContainerForm = styled.div`
  button {
    background: ${palette.green};
    color: ${palette.white};
    text-transform: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const ContainerInput = styled.div`
  .MuiOutlinedInput-root {
    background-color: ${palette.lightGray};

    .PrivateNotchedOutline-root-1 {
      border-width: 0;
    }
  }

  margin-top: 4px;
  margin-bottom: 4px;
`;

export const ContainerDescriptionInput = styled.div`
  .MuiOutlinedInput-root {
    background-color: ${palette.lightGray};
    display: flex;
    align-items: flex-start;
    height: 112px;

    .PrivateNotchedOutline-root-1 {
      border-width: 0;
    }
  }

  margin-top: 4px;
  margin-bottom: 4px;
`;

export const TitleInput = styled(MttGrid).attrs({})`
  width: 100%;
  font-size: ${window.innerWidth < 320 ? "12px" : "14px"};
  font-weight: 600;
  color: ${palette.black};
  margin-bottom: 4px;
`;
