import { useEffect } from "react";

import HeaderBack from "shared/components/HeaderBack";

import ListItemDetailsOrder from "modules/orders/components/ListItemDetailsOrder";
import OrderRefusedDialog from "modules/orders/components/OrderRefusedDialog";
import OrderSummary from "modules/orders/components/OrderSummary";
import { OrderRepository } from "modules/orders/repositories/OrderRepository";
import { useFetchFindOrder } from "modules/orders/hooks/use-fetch-find-order";
import MttButton from "shared/components/MttButton/MttButton";
import { useAlert } from "shared/hooks/useAlert";
import { createCodeFromOrderNumber } from "shared/utils/string";
import OrderHour from "modules/orders/components/OrderHour";
import OrderAddress from "modules/orders/components/OrderAddress";
import { useHistory, useParams } from "react-router";
import RefuseDialog from "shared/components/RefuseDialog";
import { FullPage } from "../StatusOrder/styles";
import { Container } from "../OrderManagement/styles";

import {
  ContainerButtons,
  GridAddress,
  GridButtons,
  GridItemsOrder,
  OrderGrid,
  StyledButtonAccept,
  StyledButtonRefuse,
  Subtitle,
  Title,
} from "./styles";
import { useActionHandler } from "./use-modal-controls";

import { SimpleAlert } from "shared/components/SimpleAlert";
import { Loading } from "shared/components/Loading";

type Props = { repository: OrderRepository };

export function GetOrderNumber(orderNumber: string) {
  return orderNumber.slice(18, 24);
}

export default function NewOrderPage({ repository }: Props) {
  const { id: orderId } = useParams<{ id: string }>();

  const history = useHistory();

  const {
    order,
    errorMessage: errorMessageFindOrder,
    fetching: fetchingFindOrder,
    findOrder,
  } = useFetchFindOrder(repository);

  useEffect(() => {
    if (orderId !== undefined && !!findOrder) findOrder(orderId);
  }, [findOrder, orderId]);

  const actionHandler = useActionHandler();
  const alert = useAlert();

  useEffect(() => {
    if (!!errorMessageFindOrder) {
      alert.setAlertMessage(errorMessageFindOrder, "error", true);
    } else {
      alert.clearAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessageFindOrder]);

  async function handleRefuseOrder() {
    try {
      alert.clearAlert();
      await repository.updateStatus(orderId, "Recusado");
      actionHandler.setShowRefusedModal(true);
      actionHandler.setShowRefuseModal(false);

      alert.setAlertMessage("Recusado com sucesso.", "success", true);
    } catch (err: any) {
      alert.setAlertMessage(err.message, "error", true);
    }
  }

  function handleGoBack() {
    history.goBack();
  }

  const pathToFinishAction = "/";

  return fetchingFindOrder ? (
    <Loading />
  ) : (
    <>
      <FullPage>
        <Container>
          <HeaderBack button={handleGoBack} title="Novo Pedido" />

          <Title>{`${order?.clientName} • ${createCodeFromOrderNumber(
            order?.order.requestNumber
          )}`}</Title>
          <Subtitle>O que deseja fazer com esse pedido?</Subtitle>

          <ContainerButtons>
            <GridButtons>
              <StyledButtonRefuse>
                <MttButton
                  variant="contained"
                  color="inherit"
                  onClick={() => {
                    actionHandler.setShowRefuseModal(true);
                  }}
                >
                  recusar
                </MttButton>
              </StyledButtonRefuse>
              <StyledButtonAccept>
                <MttButton
                  variant="contained"
                  color="inherit"
                  onClick={() => {
                    actionHandler.setShowAcceptModal(true);
                  }}
                >
                  aceitar
                </MttButton>
              </StyledButtonAccept>
            </GridButtons>
          </ContainerButtons>
          <OrderGrid>
            <OrderHour
              valorTotal={order.order.totalValue}
              payment={order.order.paymentType}
              date={order.order.date}
            />
          </OrderGrid>
          <GridAddress>
            <OrderAddress address={order.order.address} />
          </GridAddress>
          <GridItemsOrder className="order-items">
            <ListItemDetailsOrder order={order} />
          </GridItemsOrder>
        </Container>

        {actionHandler.showRefuseModal && (
          <RefuseDialog
            open={actionHandler.showRefuseModal}
            close={() => actionHandler.setShowRefuseModal(false)}
            order={order.clientName}
            refuse={() => {
              handleRefuseOrder();
            }}
          />
        )}

        {actionHandler.showRefusedModal && (
          <OrderRefusedDialog
            open={actionHandler.showRefusedModal}
            order={order}
            finish={() => {
              history.push(pathToFinishAction);
            }}
          />
        )}

        {actionHandler.showAcceptModal && (
          <OrderSummary
            order={order}
            finish={() => {
              history.push(pathToFinishAction);
            }}
          />
        )}
      </FullPage>
      <SimpleAlert
        message={alert.message}
        type={alert.type}
        show={alert.show}
        setShow={alert.setShow}
      />
    </>
  );
}
