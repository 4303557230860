import { EStatusOrder, IItemsProps } from "../types";
import { statusTypeTexts } from "../types/typesTexts";

export function statusNumberToStatusType(status: number): EStatusOrder {
  switch (status) {
    case 1:
      return statusTypeTexts.IN_PREPARATION;
    case 2:
      return statusTypeTexts.READY;
    case 3:
      return statusTypeTexts.ON_WAY;
    case 4:
      return statusTypeTexts.DELIVERED;
    case 5:
      return statusTypeTexts.REFUSED;
    case 0:
    default:
      return statusTypeTexts.PENDING;
  }
}

export function statusTypeToStatusNumber(statusType: EStatusOrder): number {
  let statusValue = 0;

  switch (statusType) {
    case statusTypeTexts.IN_PREPARATION:
      statusValue = 1;
      break;
    case statusTypeTexts.READY:
      statusValue = 2;
      break;
    case statusTypeTexts.ON_WAY:
      statusValue = 3;
      break;
    case statusTypeTexts.DELIVERED:
      statusValue = 4;
      break;
    case statusTypeTexts.REFUSED:
      statusValue = 5;
      break;
    case statusTypeTexts.PENDING:
    default:
      statusValue = 0;
  }

  return statusValue;
}

export function handleCalcTotalItems(items: IItemsProps[]) {
  let aux = 0;

  items.forEach((item) => {
    aux = aux + item.quantity;
  });

  if (aux > 1) return `${aux} itens`;
  return `${aux} item`;
}
