import styled from "styled-components";
import palette from "shared/theme/palette";
import MttGrid from "shared/components/MttGrid/MttGrid";
import MttButton from "shared/components/MttButton/MttButton";

export const Header = styled(MttGrid)`
  background: ${palette.white};
  display: flex;
  padding: 16px;
  padding-bottom: 16px;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 10vh;

  button {
    background: ${palette.green};
    color: ${palette.white};
    height: 40px;
    padding: 12px;
    text-transform: none;
    width: 140px;

    &:hover {
      background: ${palette.green};
    }
  }
`;

export const Title = styled(MttGrid)`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
`;

export const Button = styled(MttButton)``;
