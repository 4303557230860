import cx from "classnames";
import { EStatusOrder } from "modules/orders/types";
import { KeyValuePair } from "shared/models/KeyValuePair";
import useDeviceDetect from "shared/hooks/useDeviceDetect";

import {
  GridButtonClean,
  GridButtonDelivered,
  GridButtonDone,
  GridButtonPending,
  GridButtonPreparation,
  GridButtonProgress,
  GridButtonRefused,
  GridLineButtons,
  GridLineCleanButton,
  StyledDelivered,
  StyledDone,
  StyledMttButton,
  StyledPending,
  StyledPreparation,
  StyledProgress,
  StyledRefused,
} from "./styles";

export type ListFilterOrderProps = {
  filter: Array<KeyValuePair<boolean, EStatusOrder>>;
  filterUpdate: (index: number) => void;
  filterReset: () => void;
};

export function ListFilterOrder({
  filter,
  filterUpdate,
  filterReset,
}: ListFilterOrderProps) {
  const { isMobile } = useDeviceDetect();

  return (
    <div className="gridButtons">
      <GridLineButtons>
        <StyledPending
          selected={filter[0].Key}
          className={cx({ desktop: !isMobile })}
        >
          <GridButtonPending>
            <StyledMttButton
              color="inherit"
              variant="contained"
              onClick={() => filterUpdate(0)}
            >
              Pendentes
            </StyledMttButton>
          </GridButtonPending>
        </StyledPending>

        <StyledPreparation
          selected={filter[1].Key}
          className={cx({ desktop: !isMobile })}
        >
          <GridButtonPreparation>
            <StyledMttButton
              color="inherit"
              variant="contained"
              onClick={() => filterUpdate(1)}
            >
              Em preparo
            </StyledMttButton>
          </GridButtonPreparation>
        </StyledPreparation>

        <StyledDone
          selected={filter[2].Key}
          className={cx({ desktop: !isMobile })}
        >
          <GridButtonDone>
            <StyledMttButton
              color="inherit"
              variant="contained"
              onClick={() => filterUpdate(2)}
            >
              Prontos
            </StyledMttButton>
          </GridButtonDone>
        </StyledDone>
      </GridLineButtons>
      <GridLineButtons>
        <StyledProgress
          selected={filter[3].Key}
          className={cx({ desktop: !isMobile })}
        >
          <GridButtonProgress>
            <StyledMttButton
              color="inherit"
              variant="contained"
              onClick={() => filterUpdate(3)}
            >
              A caminho
            </StyledMttButton>
          </GridButtonProgress>
        </StyledProgress>

        <StyledDelivered
          selected={filter[4].Key}
          className={cx({ desktop: !isMobile })}
        >
          <GridButtonDelivered>
            <StyledMttButton
              color="inherit"
              variant="contained"
              onClick={() => filterUpdate(4)}
            >
              Entregues
            </StyledMttButton>
          </GridButtonDelivered>
        </StyledDelivered>

        <StyledRefused
          selected={filter[5].Key}
          className={cx({ desktop: !isMobile })}
        >
          <GridButtonRefused>
            <StyledMttButton
              color="inherit"
              variant="contained"
              onClick={() => filterUpdate(5)}
            >
              Recusados
            </StyledMttButton>
          </GridButtonRefused>
        </StyledRefused>
      </GridLineButtons>
      <GridLineCleanButton>
        <GridButtonClean>
          <StyledMttButton
            color="inherit"
            variant="contained"
            onClick={() => filterReset()}
          >
            Limpar filtros
          </StyledMttButton>
        </GridButtonClean>
      </GridLineCleanButton>
    </div>
  );
}
