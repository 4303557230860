import { EPaymentType, EStatusOrder } from ".";

type StatusTypeTextsProps = {
  IN_PREPARATION: EStatusOrder;
  READY: EStatusOrder;
  ON_WAY: EStatusOrder;
  DELIVERED: EStatusOrder;
  REFUSED: EStatusOrder;
  PENDING: EStatusOrder;
};

export const statusTypeTexts: StatusTypeTextsProps = {
  IN_PREPARATION: "Em Preparo",
  READY: "Pronto",
  ON_WAY: "A Caminho",
  DELIVERED: "Entregue",
  REFUSED: "Recusado",
  PENDING: "Pendente",
};

type PaymentTypeTextsProps = {
  CREDIT_CARD: EPaymentType;
  DEBIT_CARD: EPaymentType;
  PIX: EPaymentType;
  MONEY: EPaymentType;
};

export const paymentTypeTexts: PaymentTypeTextsProps = {
  CREDIT_CARD: "Cartão Credito",
  DEBIT_CARD: "Cartão Debito",
  PIX: "Pix",
  MONEY: "Dinheiro",
};
