import { CompanyRemote } from "../types/CompanyRemote";
import { ICompanyProps } from "../types";

export class CompanyRemoteFactory {
  public factoryMethod(company: CompanyRemote): ICompanyProps {
    return {
      _id: company._id,
      contact_phone: company.contact_phone,
      contact_email: company.contact_email,
      contact_name: company.contact_name,
      contact_nickname: company.contact_nickname,
      company_id: company.company_id,
      company_corporate_name: company.company_corporate_name,
      company_business_name: company.company_business_name,
      company_description: company.company_description,
      company_image: company.company_image,
      company_phone: company.company_phone,
      company_email: company.company_email,
      company_pix: company.company_pix,
      company_hashtag: company.company_hashtag,
      hashtag_updated: company.hashtag_updated,
      welcome_message: company.welcome_message,
      freight: company.freight,
      company_status: company.company_status,
      company_address: company.company_address,
      owner_id: company._id,
      created: new Date(company.created),
      last_updated: new Date(company.last_updated),
    };
  }
}
