import {
  GetOrder,
  GetOrders,
  GetOrdersByStatus,
  UpdateOrder,
} from "modules/services/OrderService";
import { PaginationType } from "shared/types";
import { IOrder } from "shared/models/OrderModel";
import { ClientOrderFactory } from "../factories/ClientOrderFactory";
import { EStatusOrder } from "../types";
import { ClientOrder } from "../types/ClientOrder";
import { statusTypeToStatusNumber } from "../utils";
import { ResumeOrderRemote } from "../types/DetailsOrderRemote";
import { resumeOrderFactory } from "../factories/ResumeOrderFactory";
export class OrderRepository {
  constructor(
    private readonly orderClientFactory: ClientOrderFactory,
    private readonly dispatch: (state: boolean) => void
  ) {}

  async findOrderByStatus(
    query?: PaginationType & { request: number[]; company_id?: string }
  ) {
    const resp = await GetOrdersByStatus<{ data: ResumeOrderRemote[] }>(
      this.dispatch,
      query
    );

    if (resp.Data) {
      return resp.Data.data.map((order: ResumeOrderRemote) =>
        resumeOrderFactory(order)
      );
    }

    return [];
  }

  async findOrders(query?: PaginationType) {
    const resp: any = await GetOrders(this.dispatch, query);

    const self = this;
    const data: ClientOrder[] = resp.Data.data.map((order: IOrder) =>
      self.orderClientFactory.factoryMethod(order)
    );

    return data;
  }

  async findOrder(uuid: string) {
    const resp: any = await GetOrder(uuid, this.dispatch);
    return this.orderClientFactory.factoryMethod(resp.Data.data);
  }

  async updateStatus(uuid: string, statusType: EStatusOrder) {
    const resp: any = await UpdateOrder(
      {
        order_id: uuid,
        status: statusTypeToStatusNumber(statusType),
      },
      this.dispatch
    );

    return this.orderClientFactory.factoryMethod(resp.Data.data);
  }
}
