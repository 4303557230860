import styled from "styled-components";
import MttButton from "shared/components/MttButton/MttButton";
import MttGrid from "shared/components/MttGrid/MttGrid";
import palette from "shared/theme/palette";

export interface SelectedProp {
  selected: boolean;
}

export const OrderFilterButton = styled(MttGrid)`
  flex: 0 0 32%;

  button {
    padding: 10px !important;
  }

  white-space: nowrap;
  @media (max-width: 360px) {
    flex: 0 0 104px;
  }
`;

export const NewProductButton = styled(MttButton).attrs({
  children: "Pendente",
})``;

export const Container = styled(MttGrid)`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const GridHeader = styled(MttGrid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${palette.lightGray};
  box-shadow: 0 0 4px 0 ${palette.gray};
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 8px 0 8px 0;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${palette.gray};
`;

export const ContainerButtonClean = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px 0;
`;

export const ContainerName = styled.div``;
export const GridLineCleanButton = styled(OrderFilterButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 32%;
  @media screen and (max-width: 360px) {
    .cleanButton {
      flex: 0 0 114px;
    }
  }
`;

export const GridLineButtons = styled(MttGrid)`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 3%;
`;

export const StyledPending = styled(OrderFilterButton)<{ selected: boolean }>`
  .pendingButton {
    .MuiButton-colorInherit {
      background-color: ${(props) =>
        props.selected === true ? palette.pending : "#FFF"};
      color: ${(props) => (props.selected ? "#FFF" : "#707070")};
    }
  }

  &.desktop .pendingButton:hover .MuiButton-colorInherit {
      background-color: ${palette.pending};
      color: ${palette.white};
    }
  }
`;

export const GridButtonPending = styled(MttGrid).attrs({
  className: "pendingButton",
})``;

export const StyledDelivered = styled(OrderFilterButton)<{ selected: boolean }>`
  .deliveredButton {
    .MuiButton-colorInherit {
      background-color: ${(props) =>
        props.selected === true ? palette.delivered : "#FFF"};
      color: ${(props) => (props.selected ? "#FFF" : "#707070")};
    }
  }

  &.desktop .deliveredButton:hover .MuiButton-colorInherit {
    background-color: ${palette.delivered};
    color: ${palette.white};
    }
  }
`;

export const GridButtonDelivered = styled(MttGrid).attrs({
  className: "deliveredButton",
})``;

export const StyledDone = styled(OrderFilterButton)<{ selected: boolean }>`
  .doneButton {
    .MuiButton-colorInherit {
      background-color: ${(props) =>
        props.selected === true ? palette.done : "#FFF"};
      color: ${(props) => (props.selected ? "#FFF" : "#707070")};
    }
  }

  &.desktop .doneButton:hover .MuiButton-colorInherit {
    background-color: ${palette.done};
    color: ${palette.white};
    }
  }
`;

export const GridButtonDone = styled(MttGrid).attrs({
  className: "doneButton",
})``;

export const StyledPreparation = styled(OrderFilterButton)<{
  selected: boolean;
}>`
  .preparationButton {
    .MuiButton-colorInherit {
      background-color: ${(props) =>
        props.selected === true ? palette.preparation : "#FFF"};
      color: ${(props) => (props.selected ? "#FFF" : "#707070")};
    }
  }

  &.desktop .preparationButton:hover .MuiButton-colorInherit {
    background-color: ${palette.preparation};
    color: ${palette.white};
    }
  }
`;

export const GridButtonPreparation = styled(MttGrid).attrs({
  className: "preparationButton",
})``;

export const StyledProgress = styled(OrderFilterButton)<{ selected: boolean }>`
  .progressButton {
    .MuiButton-colorInherit {
      background-color: ${(props) =>
        props.selected === true ? palette.progress : "#FFF"};
      color: ${(props) => (props.selected ? "#FFF" : "#707070")};
    }
  }

  &.desktop .progressButton:hover .MuiButton-colorInherit {
    background-color: ${palette.progress};
    color: ${palette.white};
    }
  }
`;

export const GridButtonProgress = styled(MttGrid).attrs({
  className: "progressButton",
})``;

export const StyledRefused = styled(OrderFilterButton)<{ selected: boolean }>`
  .refusedButton {
    .MuiButton-colorInherit {
      background-color: ${(props) =>
        props.selected === true ? palette.refused : "#FFF"};
      color: ${(props) => (props.selected ? "#FFF" : "#707070")};
    }
  }

  &.desktop .refusedButton:hover .MuiButton-colorInherit {
    background-color: ${palette.refused};
    color: ${palette.white};
    }
  }
`;

export const GridButtonRefused = styled(MttGrid).attrs({
  className: "refusedButton",
})``;

export const GridButtonClean = styled(MttGrid).attrs({
  className: "cleanButton",
})``;

export const StyledMttButton = styled(MttButton).attrs({
  className: "defaultButtonStyle",
})``;
