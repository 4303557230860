import styled, { css } from "styled-components";
import MttGrid from "shared/components/MttGrid/MttGrid";

export const SOrderList = styled.div``;

export const OrderListGrid = styled(MttGrid)`
  display: flex;
  flex-direction: column;
`;

export const hoverGridItem = css<{ focus: string }>`
  background-color: ${(props) => props.focus};

  .MuiGrid-root {
    color: #fff;
  }

  span {
    color: #fff;
  }

  #iconGrid {
    background-color: #fff;
    color: ${(props) => props.focus};
  }
`;

export const GridItem = styled.div<{ focus: string; isMobile?: boolean }>`
  width: 100%;

  display: flex;

  padding: 16px;
  border-bottom: 1px solid #f3f3f3;

  ${({ isMobile }) =>
    !isMobile &&
    `
    cursor: pointer;
  :hover {
    background-color: #f3f3f3
  }
  `};

  :active {
    ${hoverGridItem};
  }
`;

export const GridBody = styled(MttGrid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
`;

export const GridStatus = styled(MttGrid)`
  align-items: flex-start;
  padding-right: 16px;
`;

export const ContentStatus = styled(MttGrid)<{ status: string }>`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  background-color: ${(props) => props.status};
  color: #ffffff;
`;

export const GridDescription = styled(MttGrid)``;

export const GridValue = styled(MttGrid)`
  text-align: right;
`;

export const TitleCostumer = styled(MttGrid)`
  white-space: nowrap;
  font-size: ${window.innerWidth < 320 ? "14px" : "16px"};
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  padding-bottom: 4px;
  flex: 0 0 100%;
`;

export const SubTitle = styled.span`
  font-size: ${window.innerWidth < 320 ? "12px" : "14px"};
  font-weight: 600;
  color: #707070;
`;

export const TextDefault = styled(MttGrid)`
  font-size: ${window.innerWidth < 320 ? "12px" : "14px"};
  font-weight: 400;
  color: #707070;
  padding-bottom: 4px;
`;

export const TextPrice = styled(MttGrid)`
  font-size: ${window.innerWidth < 320 ? "14px" : "20px"};
  font-weight: bold;
  color: #000;
`;

export const ContentStatusAndTotalValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;
