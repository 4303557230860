import { json } from "../RequestService";
import {
  IGetCompaniesResponse,
  ISaveCompanyRequest,
  ISingleCompanyResponse,
} from "./types";

const defaultPath = "companies";

export const GetCompanies = async (dispatch: (state: boolean) => void) => {
  const action = `${defaultPath}?skip=0&limit=100`;
  return await json<IGetCompaniesResponse>("GET", action, dispatch);
};

export const GetCompanyById = async (
  company_id: string,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${company_id}`;
  return await json<ISingleCompanyResponse>("GET", action, dispatch);
};

export const SaveCompany = async (
  request: ISaveCompanyRequest,
  dispatch: (state: boolean) => void
) => {
  return await json<ISingleCompanyResponse>(
    "POST",
    defaultPath,
    dispatch,
    request.company
  );
};

export const RemoveCompany = async (
  company_id: string,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${company_id}`;
  return await json<ISingleCompanyResponse>("DELETE", action, dispatch);
};

export const UpdateCompany = async (
  request: ISaveCompanyRequest,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${request.company._id}`;
  return await json<ISingleCompanyResponse>(
    "PATCH",
    action,
    dispatch,
    request.company
  );
};

export const UpdateCompanyHashtag = async (
  company_id: string,
  hashtag_name: string,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${company_id}/update-hashtag`;
  await json<void>("PATCH", action, dispatch, hashtag_name);
};
