import { FormLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select/Select';
import styled from 'styled-components';
import MttGrid from '../MttGrid/MttGrid';


export const StyledSelect = styled(Select)``;

export const StyledFormLabel = styled(FormLabel)``;

const Container = styled(MttGrid)`
  width: 100%;
`;
export default Container;
