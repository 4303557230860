import styled from "styled-components";
import MttGrid from "shared/components/MttGrid/MttGrid";

export const DeliveryInfos = styled(MttGrid)`
  padding: 8px 0;
`;

export const GridTitle = styled(MttGrid)`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleLeft = styled(MttGrid)<{ sizeTitle?: number }>`
  font-size: ${(props) => (props.sizeTitle ? `${props.sizeTitle}px` : "20px")};
  color: #000000;
  font-weight: bold;
`;

export const DescriptionDelivery = styled(MttGrid)`
  padding-top: 8px;
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  flex-wrap: wrap;
  line-height: 1.5;
`;
