import React from "react";
import { IDisplayHeader } from "../types";
import { Header, Title, Button } from "./styles";

export default function DisplayHeader(props: IDisplayHeader) {
  const {
    title,
    buttonIcon,
    buttonLabel,
    buttonOnClick
  } = props;

  return (
    <Header>
      <Title>{title}</Title>
      <Button
        onClick={buttonOnClick}
        startIcon={buttonIcon}
      >
        {buttonLabel}
      </Button>
    </Header>
  );
}