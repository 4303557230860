import {
  Container,
  Title,
  ItemContent,
  Description,
  ImageContainer,
  Image,
} from "./styles";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { IListItem } from "./types";
import { Draggable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";

export default function ListItem(props: IListItem) {
  const history = useHistory();
  const {
    dragKey,
    index,
    setShowEditProduct,
    // setSelectedProduct,
    productKey,
    description,
    image,
    price,
    title,
    disabled,
  } = props;

  const convertedPrice = price
    ? price.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    : 0;

  const handleOnClick = () => {
    setShowEditProduct(true);
    history.push("/products/" + productKey);
  };

  return (
    <Draggable draggableId={dragKey} index={index} isDragDisabled={disabled}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
        >
          <ImageContainer>
            <Image src={image} alt={"list-item-img"} />
          </ImageContainer>

          <ItemContent onClick={() => handleOnClick()}>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <Title>{convertedPrice}</Title>
          </ItemContent>
          <DragIndicatorIcon />
        </Container>
      )}
    </Draggable>
  );
}
