import styled from "styled-components";
import MttGrid from "shared/components/MttGrid/MttGrid";

export const OrderListGrid = styled(MttGrid)`
  display: flex;
  flex-direction: column;
`;

export const ContentButtonRefresh = styled.span`
  svg {
    color: gray;
  }
`;
