import { useCallback, useState } from "react";
import { CompanyRepository } from "../repositories/CompanyRepository";
import { ICompanyProps } from "../types";

export function useFetchFindCompany(repository: CompanyRepository) {
  const [company, setCompany] = useState<ICompanyProps>({
    _id: "",
    contact_phone: "",
    contact_email: "",
    contact_name: "",
    contact_nickname: "",
    company_id: "",
    company_corporate_name: "",
    company_business_name: "",
    company_description: "",
    company_image: "",
    company_phone: "",
    company_email: "",
    company_pix: 0,
    company_hashtag: "",
    hashtag_updated: false,
    welcome_message: "",
    freight: 0,
    company_status: 0,
    company_address: {
      street: "",
      number: "",
      neighborhood: "",
      city: "",
      state: "",
      zip_code: "",
      location: {
        lat: 0,
        long: 0,
      },
    },
    owner_id: "",
    created: new Date(),
    last_updated: new Date(),
  });

  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<string>();

  const findCompany = useCallback(
    async (uuid: string) => {
      try {
        setFetching(true);

        const resp = await repository.findCompany(uuid);

        setCompany(resp);
        setFetching(false);
      } catch (err: any) {
        setError(err.message);
        setFetching(false);
      }
    },
    [repository]
  );

  return {
    company,
    fetching,
    findCompany,
    errorMessage: error,
  };
}
