import React from "react";

import Button from "@material-ui/core/Button/Button";

import { IButtonPropsV4 } from "./types";

const MttButton: React.FC<IButtonPropsV4> = (props: IButtonPropsV4) => {
  const { children } = props;

  return <Button {...props}> {children} </Button>;
};

export default MttButton;
