import MttDialog from "../MttDialog/index";
import MttButton from "../MttButton/MttButton";
import {
  Container,
  GridButtons,
  StyledButtonDeny,
  StyledButtonRefuse,
  Subtitle,
  Title,
} from "./styles";

type Props = {
  open: boolean;
  close(bol: boolean): void;
  refuse(): void;
  order: string;
};

export default function RefuseDialog(props: Props) {
  const { open, close, order, refuse } = props;
  return (
    <MttDialog open={open} onClose={() => close(false)}>
      <Container>
        <Title>{order}</Title>
        <Subtitle>Deseja mesmo recusar o pedido ?</Subtitle>
        <GridButtons>
          <StyledButtonRefuse>
            <MttButton
              variant="contained"
              color="inherit"
              onClick={() => close(false)}
            >
              retornar
            </MttButton>
          </StyledButtonRefuse>
          <StyledButtonDeny>
            <MttButton
              variant="contained"
              color="inherit"
              onClick={() => refuse()}
            >
              recusar
            </MttButton>
          </StyledButtonDeny>
        </GridButtons>
      </Container>
    </MttDialog>
  );
}
