import { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AuthContext, { AuthProvider } from "shared/contexts/auth";
import GlobalStyle from "shared/theme/global";

import { PagesFactory as OrdersPagesFactory } from "modules/orders/factories/OrdersPagesFactory";
import { PagesFactory as ProductPagesFactory } from "modules/products/factories/ProductsPagesFactory";
import { PagesFactory as StorePagesFactory } from "modules/store-information/factories/PagesFactory";
// import { Loading } from "shared/components/Loading";

function Layout() {
  const context = useContext(AuthContext);
  const factoryProducts = ProductPagesFactory(context.dispatch);
  const factoryOrders = OrdersPagesFactory(context.dispatch);
  const factoryStore = StorePagesFactory(context.dispatch);

  // if (!context.signed && context.loading) return <Loading />;

  return context.signed ? (
    <>
      <Router>
        <Switch>
          <Route
            path={`/orders/:id/details`}
            exact
            component={factoryOrders.makeDetailsOrderPage}
          />
          <Route
            path={`/orders/:id/new`}
            exact
            component={factoryOrders.makeNewOrderPage}
          />
          <Route
            path={`/orders/:id/status`}
            exact
            component={factoryOrders.makeStatusOrderPage}
          />
          <Route
            path={`/`}
            exact
            component={factoryOrders.makeOrderManagementPage}
          />
          <Route
            path="/products"
            exact
            component={factoryProducts.makeManagementPage}
          />
          <Route
            path="/products/new"
            exact
            component={factoryProducts.makeNewProductPage}
          />
          <Route
            path="/products/:id"
            exact
            component={factoryProducts.makeNewProductPage}
          />
          <Route
            path="/store-information"
            exact
            component={factoryStore.makeManagementPage}
          />
        </Switch>
      </Router>
      <GlobalStyle />
    </>
  ) : (
    <>
      <h3>Usuário não autorizado.</h3>
    </>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <Layout />
    </AuthProvider>
  );
}
