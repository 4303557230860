import { useState } from "react";
import { AlertType } from "shared/components/SimpleAlert";

export function useAlert() {
  const [showAlert, setShowAlert] = useState(false);

  const [alert, setAlert] = useState<{ message?: string; type?: AlertType }>({
    message: undefined,
    type: undefined,
  });

  function setAlertMessage(message: string, type: AlertType, show?: boolean) {
    setShowAlert(!!show);
    setAlert((prev) => ({
      ...prev,
      message: message,
      type: type,
    }));
  }

  function clearAlert() {
    setAlert((prev) => ({
      ...prev,
      message: undefined,
      type: undefined,
    }));
  }

  return {
    show: showAlert,
    setShow: setShowAlert,
    message: alert.message,
    type: alert.type,
    setAlertMessage,
    clearAlert,
  };
}
