import { GridIcon, Header, Title, DeleteButton } from "./styles";
import { ArrowBack, Delete } from "@material-ui/icons";

type Props = {
  title: string;
  button(): void;
  deleteButton?(): void;
};

export default function HeaderBack(props: Props) {
  const { title, button, deleteButton } = props;

  return (
    <Header>
      <GridIcon onClick={button}>
        <ArrowBack />
      </GridIcon>
      <Title>{title}</Title>
      {deleteButton && (
        <DeleteButton>
          <GridIcon onClick={deleteButton}>
            <Delete />
          </GridIcon>
        </DeleteButton>
      )}
    </Header>
  );
}
