import { ISingleOrderResponse } from "../OrderService/types";
import { json } from "../RequestService";
import {
  IGetProductsResponse,
  ISaveProductRequest,
  ISingleProductResponse,
  ProductPaginationType,
} from "./types";

const defaultPath = "products";

export const GetProducts = async (
  dispatch: (state: boolean) => void,
  query?: ProductPaginationType
) => {
  const action = `${defaultPath}?company_id=${query?.company_id}&skip=${
    query?.skip ?? 0
  }&limit=${query?.limit ?? 100}`;
  return await json<IGetProductsResponse>("GET", action, dispatch);
};

export const GetProduct = async (
  product_id: string,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${product_id}`;
  return await json<ISingleProductResponse>("GET", action, dispatch);
};

export const SaveProduct = async (
  request: ISaveProductRequest,
  dispatch: (state: boolean) => void
) => {
  return await json<ISingleProductResponse>(
    "POST",
    defaultPath,
    dispatch,
    request.product
  );
};

export const RemoveProduct = async (
  product_id: string,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${product_id}`;
  return await json<void>("DELETE", action, dispatch);
};

export const UpdateProduct = async (
  request: ISaveProductRequest,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${request.product._id}`;
  return await json<ISingleProductResponse>(
    "PATCH",
    action,
    dispatch,
    request.product
  );
};

export const AddProductToOrder = async (
  product_id: string,
  order_id: string,
  dispatch: (state: boolean) => void
) => {
  const action = `${defaultPath}/${product_id}/order`;
  return await json<ISingleOrderResponse>("PATCH", action, dispatch, {
    order: order_id,
  });
};
