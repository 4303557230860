import { addDays, addHours, addMinutes, isValid } from "date-fns";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { ptBR, uk } from "date-fns/locale";
import { ETimeDeliveryType } from "modules/orders/types";

export function dateUTCAmerica_SaoPaulo(date: Date | string) {
  return format(
    zonedTimeToUtc(date, "Europe/London"),
    "HH:mm - dd/MM/yyyy"
  );
}

export function dateToAmericaSaoPaulo(date: Date | string) {
  date = typeof date === "string" ? new Date(date) : date;

  return isValid(date)
    ? zonedTimeToUtc(date, "America/Sao_Paulo", {
        locale: ptBR,
      })
    : date;
}

export function dateToUtcUniversalTime(date: Date | string) {
  date = typeof date === "string" ? new Date(date) : date;

  return isValid(date)
    ? zonedTimeToUtc(date, "Etc/UTC", {
        locale: uk,
      })
    : date;
}

export function createForecastFromSelectedTime(
  timeSelected: string | ETimeDeliveryType,
  timeValue: string
): {
  forecastUTC0: Date;
  forecastUTC3: Date;
  unit: ETimeDeliveryType;
} {
  let forecast = new Date();
  let timeDeliveryType: ETimeDeliveryType;

  switch (timeSelected) {
    case "0":
      timeDeliveryType = "Min";
      forecast = addMinutes(forecast, parseInt(timeValue));
      break;
    case "1":
      timeDeliveryType = "Hora(s)";
      forecast = addHours(forecast, parseInt(timeValue));

      break;
    default:
      timeDeliveryType = "Dia(s)";
      forecast = addDays(forecast, parseInt(timeValue));
      break;
  }

  return {
    forecastUTC0: dateToUtcUniversalTime(forecast),
    forecastUTC3: forecast,
    unit: timeDeliveryType,
  };
}
