import { IRemoteCompanyTokenResponse } from "./types";

let apiUrl = process.env.REACT_APP_API_URL ?? "";
apiUrl = apiUrl.replace("/api/", "/");

const jtwTokenUrl = `${apiUrl}jwt/token`;

export const RefreshCompanyToken = async (): Promise<IRemoteCompanyTokenResponse | void> => {
  const action = `${jtwTokenUrl}/refresh`;
  const refreshToken = localStorage.getItem("refresh_token");

  if (!!refreshToken) {
    const resp = await fetch(action, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Refresh: refreshToken,
      },
    });

    return await resp.json();
  }
};

export const LogOut = async (): Promise<void> => {
  const action = "logout";

  const refreshToken = localStorage.getItem("refresh_token");
  const token = localStorage.get("token");

  if (!!refreshToken && !!token) {
    await fetch(apiUrl + action, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Refresh: refreshToken,
        Authorization: token,
      },
    });
  }

  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
};
