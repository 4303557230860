import React from 'react';

import StyledTextField from './styles';
import { IMttInputProps } from './types';

const MttInput: React.FC<IMttInputProps> = (props: IMttInputProps) => {
	const {
		label,
		value,
		placeholder,
		required,
		onChange,
		onBlur,
		error,
		type,
		disabled,
		select,
		variant,
		multiline,
		rows,
		InputProps,
		focused,
		helperText,
		children
	} = props;

	return (
		<StyledTextField
			variant={variant}
			color="primary"
			disabled={disabled}
			value={value}
			type={type}
			select={select}
			placeholder={placeholder}
			onChange={onChange}
			onBlur={onBlur}
			required={required}
			label={label}
			helperText={helperText}
			fullWidth
			multiline={multiline}
			rows={rows}
			focused={focused}
			error={error}
			InputProps={InputProps}
			children={children}
		// onClick={() => { setErrorInfo(!errorInfo); }}
		/>
	);
};

export default MttInput;
