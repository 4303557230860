import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export type AlertType = "error" | "warning" | "info" | "success";

export interface SimpleAlertProps {
  message?: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  type?: AlertType;
}

export function SimpleAlert({
  message,
  type,
  show,
  setShow,
}: SimpleAlertProps) {
  function handleClose() {
    setShow(false);
  }

  if (show) {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }

  return (
    <Snackbar open={show} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} variant="filled" severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
}
