import { useEffect, useLayoutEffect, useState } from "react";
import { Add } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import DisplayHeader from "modules/products/components/DisplayHeader";
import EmptyArea from "modules/products/components/EmptyArea";
import ListProducts from "modules/products/components/ListProducts";
import { ProductRepository } from "modules/products/repositories/ProductRepository";
import { useFetchFindProducts } from "modules/products/hooks/use-fetch-find-products";
import { Loading } from "shared/components/Loading";
import { Container } from "./styles";

const emptyMessage = "Vish... Você ainda não tem nenhuma produto cadastrado!";

type Props = {
  repository: ProductRepository;
};

export default function ProductManagement({ repository }: Props) {
  const history = useHistory();

  const { fetchProducts, products, fetching } = useFetchFindProducts(
    repository
  );

  useLayoutEffect(() => {
    fetchProducts().finally();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showNewProduct /*setShowNewProduct*/] = useState<boolean>(false);
  const [showEditProduct, setShowEditProduct] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(fetching);
  }, [fetching]);

  return (
    <>
      {!showNewProduct && !showEditProduct && (
        <>
          <DisplayHeader
            title="Produtos"
            buttonIcon={<Add />}
            buttonLabel="Novo produto"
            buttonOnClick={() => {
              history.push(`/products/new`);
            }}
          />
          <Container>
            {!!loading && <Loading />}

            {!!products && products.length > 0 && (
              <ListProducts
                setShowEditProduct={setShowEditProduct}
                list={products}
                setLoading={setLoading}
                loading={loading}
              />
            )}

            {!!products && products.length === 0 && (
              <EmptyArea text={emptyMessage} />
            )}
          </Container>
        </>
      )}
    </>
  );
}
