import { CompanyRepository } from "modules/store-information/repositories/CompanyRepository";
import AboutStore from "pages/store-information/AboutStore";
import { CompanyRemoteFactory } from "./CompanyRemoteFactory";

export function PagesFactory(dispatch: (state: boolean) => void) {
  const remoteFactory = new CompanyRemoteFactory();
  const repository = new CompanyRepository(remoteFactory, dispatch);

  const makeManagementPage = () => {
    return <AboutStore repository={repository} />;
  };

  return {
    makeManagementPage,
  };
}
