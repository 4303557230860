import { IProductProps } from "modules/products/types";
import { AddProductToOrder } from "modules/services/ProductService";
import { useContext, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AuthContext from "shared/contexts/auth";
import ListItem from "./ListItem";
import { Container } from "./styles";

export interface IListProducts {
  setShowEditProduct: (arg0: boolean) => void;
  // setSelectedProduct: (arg0: IProductProps) => void;
  list: IProductProps[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

export default function ListProducts(props: IListProducts) {
  const { setShowEditProduct, list, setLoading, loading } = props;
  const [productList, setProductList] = useState<IProductProps[]>(list);
  const { dispatch } = useContext(AuthContext);

  const getList = (provided: any) => (
    <Container innerRef={provided.innerRef} {...provided.droppableProps}>
      {productList.map(({ _id, description, images, price, title }, index) => (
        <ListItem
          key={`list-item-${index}`}
          dragKey={`list-item-${index}`}
          index={index}
          setShowEditProduct={setShowEditProduct}
          // setSelectedProduct={setSelectedProduct}
          productKey={_id ?? ""}
          description={description}
          image={!!images && !!images.length ? images[0] : ""}
          price={price ?? 0}
          title={title}
          disabled={loading}
        />
      ))}
      {provided.placeholder}
    </Container>
  );

  async function remoteUpdateOrder(
    productId: string,
    indexFrom: number,
    indexTo: number
  ) {
    try {
      setLoading(true);

      const newOrder =
        productList.find((p) => p.order === indexTo)?.order ?? indexTo;

      await AddProductToOrder(productId, String(newOrder), dispatch);

      const newTaskIds = productList;
      const currentTask = newTaskIds[indexFrom];
      newTaskIds.splice(indexFrom, 1);
      newTaskIds.splice(indexTo, 0, currentTask);
      setProductList(newTaskIds);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log("error", error);
    }
  }

  return (
    <DragDropContext
      onDragEnd={({ source, destination }) => {
        if (!!destination && !loading) {
          const indexFrom = source.index;
          const indexTo = destination.index;
          const currentProduct = productList[indexFrom]._id;
          remoteUpdateOrder(currentProduct ?? "", indexFrom, indexTo);
        }
      }}
    >
      <Droppable droppableId="only-list">
        {(provided) => getList(provided)}
      </Droppable>
    </DragDropContext>
  );
}
