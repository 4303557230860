import FormControl from "@material-ui/core/FormControl/FormControl";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import React from "react";
import { KeyValuePair } from "shared/models/KeyValuePair";

import Container, { StyledSelect } from "./styles";
import { IMttSelectProps } from "./types";

const MttSelect: React.FC<IMttSelectProps> = (props: IMttSelectProps) => {
  const {
    fullWidth,
    label,
    required,
    error,
    value,
    values,
    onChange,
    addSelecione,
    titleSelecione,
    variant,
  } = props;

  return (
    <Container>
      <FormControl variant={variant}>
        <StyledSelect
          fullWidth={fullWidth}
          value={value}
          label={label}
          required={required}
          error={error}
          onChange={onChange}
        >
          {addSelecione && (
            <MenuItem disabled value="-1">
              {titleSelecione || "--- Selecione ---"}
            </MenuItem>
          )}
          {values.map((item: KeyValuePair<string | number, string>) => (
            <MenuItem key={item.Key} value={item.Key}>
              {item.Value}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Container>
  );
};

export default MttSelect;
