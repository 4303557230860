import { OrderRepository } from "modules/orders/repositories/OrderRepository";
import DetailsOrderPage from "pages/orders/DetailsOrder";
import NewOrderPage from "pages/orders/NewOrder";
import OrderManagementPage from "pages/orders/OrderManagement";
import StatusOrderPage from "pages/orders/StatusOrder";
import { ClientOrderFactory } from "./ClientOrderFactory";

export function PagesFactory(dispatch: (state: boolean) => void) {
  const orderClientFactory = new ClientOrderFactory();
  const orderRepository = new OrderRepository(orderClientFactory, dispatch);

  const makeStatusOrderPage = () => {
    return <StatusOrderPage repository={orderRepository} />;
  };

  const makeOrderManagementPage = () => {
    return <OrderManagementPage repository={orderRepository} />;
  };

  const makeNewOrderPage = () => {
    return <NewOrderPage repository={orderRepository} />;
  };

  const makeDetailsOrderPage = () => {
    return <DetailsOrderPage repository={orderRepository} />;
  };

  return {
    makeStatusOrderPage,
    makeOrderManagementPage,
    makeNewOrderPage,
    makeDetailsOrderPage,
  };
}
