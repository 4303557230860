import MttGrid from "shared/components/MttGrid/MttGrid";
import styled from "styled-components";

export const Container = styled(MttGrid)`
  align-items: center;
  display: flex;
  height: 90vh;

  img {
    margin-bottom: 24px;
    width: 80%;
  }
`;

export const Content = styled(MttGrid)`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 900;
`;
